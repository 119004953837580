// borrowed from https://blog.logrocket.com/using-localstorage-react-hooks/
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

function getStorageValue<T>(key: string, defaultValue: T) {
  const saved = localStorage.getItem(key);
  if (saved === null) {
    return defaultValue;
  }

  const parsed = JSON.parse(saved) as T;
  return parsed || defaultValue;
}

declare type SetValue<T> = Dispatch<SetStateAction<T>>;
export const useLocalStorage = <T>(
  key: string,
  defaultValue: T,
): [T, SetValue<T>] => {
  const [value, setValue] = useState(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
