import { useEffect, useState } from 'react';

export function useSerialNumber() {
  const [serialNumber] = useState(readSerialNumber());
  useEffect(() => {
    if (
      window.location.host === hosts.production &&
      serialNumber === SERIAL_NUMBER.ligaNova
    ) {
      window.location.host = hosts.staging;
    }
  }, [serialNumber]);
  return serialNumber;
}

const hosts = {
  production: 'porsche.charging-lounges.com',
  staging: 'porsche-staging.charging-lounges.com',
};

type ObjectValues<T> = T[keyof T];

export const SERIAL_NUMBER = {
  bingen: '1f4311efad',
  ingolstadt: '1f4311ec90',
  invalid: 'invalid',
  koblach: '1f4311ec7a',
  ligaNova: '1f4311eaec',
  wurzburg: '1f4311edf8',
} as const;

export type SerialNumber = ObjectValues<typeof SERIAL_NUMBER>;

function isValidSerialNumber(value: string): value is SerialNumber {
  const validNumbers = Object.values(SERIAL_NUMBER);
  return (
    typeof value === 'string' && validNumbers.includes(value as SerialNumber)
  );
}

function readSerialNumber() {
  const result = new URLSearchParams(window.location.search).get(
    'serial_number',
  );
  if (result === null || result === undefined || result === '') {
    return SERIAL_NUMBER.bingen;
  }
  return isValidSerialNumber(result) ? result : SERIAL_NUMBER.invalid;
}
