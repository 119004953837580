import './FaqItem.scss';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SvgIcon from '../../../components/SvgIcon/SvgIcon';
import { useSystemDevice } from '../../../hooks/useSystemDevice';

interface Args {
  question: string;
  answer: string;
  isHidden?: boolean;
  className?: string;
}

const FaqItem = ({
  question,
  answer,
  isHidden = false,
  className = '',
}: Args) => {
  const scope = 'FaqItem';

  const [isExpanded, setIsExpanded] = useState(false);

  const { t } = useTranslation();
  const systemDevice = useSystemDevice();

  if (isHidden) {
    return null;
  }

  const answerWithHtmlTags = {
    __html: t(answer),
  };

  return (
    <div className={`${scope} ${scope}--${systemDevice} ${className}`}>
      <div
        onClick={() => setIsExpanded((isExpanded) => !isExpanded)}
        className={`${scope}-questionContainer`}
      >
        <div className={`${scope}-question`}>{t(question)}</div>
        <div className={`${scope}-iconContainer`}>
          {isExpanded ? (
            <SvgIcon name={'minus'} className={`${scope}-controlIcon`} />
          ) : (
            <SvgIcon name={'plus'} className={`${scope}-controlIcon`} />
          )}
        </div>
      </div>
      <div
        dangerouslySetInnerHTML={answerWithHtmlTags}
        className={`${scope}-answer ${
          isExpanded ? `${scope}-answer--expanded` : ''
        }`}
      />
    </div>
  );
};

export default FaqItem;
