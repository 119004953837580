import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import './VideoPlayer.scss';
import { useIdleTimerContext } from 'react-idle-timer';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import Progress from '../Progress/Progress';
import SvgIcon from '../SvgIcon/SvgIcon';
import Volume from './Volume';

const USER_ACTIVITY_LIFETIME = 2000;

interface Args {
  source: string;
  previewSource?: string;
  className: string;
  loop?: boolean;
  onEnded?: (...args: unknown[]) => unknown;
  onStarted?: (...args: unknown[]) => unknown;
  enableIOSFullscreen?: boolean;
  onExitIOSFullscreen?: () => void;
  canShowControls?: boolean;
}

interface Handle {
  stop: () => void;
  play: () => Promise<unknown>;
  // allows to restore the video poster
  load: () => void;
}

const VideoPlayer = forwardRef<Handle, Args>(
  (
    {
      className,
      enableIOSFullscreen,
      loop,
      onEnded,
      onExitIOSFullscreen,
      onStarted,
      previewSource,
      source,
      canShowControls,
    },
    ref,
  ) => {
    const scope = 'VideoPlayer';

    const videoElementRef = useRef<HTMLVideoElement | iOSVideoElement>(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const systemDevice = useSystemDevice();

    const [duration, setDuration] = useState(0);
    const [currentTime, setCurrentTime] = useState(0);
    const progressPercent = useMemo(
      () => (currentTime / duration) * 100,
      [currentTime, duration],
    );
    const hasStarted = useMemo(() => currentTime > 0, [currentTime]);

    const [volume, setVolume] = useState(0);
    const [isUserActive, setIsUserActive] = useState(false);

    const idleTimer = useIdleTimerContext();

    const inactivityTimerRef = useRef<NodeJS.Timeout | null>();

    const teardownInactivityTimer = () => {
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
        inactivityTimerRef.current = null;
      }
    };

    useEffect(() => {
      return teardownInactivityTimer;
    }, []);

    useEffect(() => {
      const video = videoElementRef.current;

      if (enableIOSFullscreen && onExitIOSFullscreen && video) {
        video.addEventListener('webkitendfullscreen', onExitIOSFullscreen);

        return () =>
          video.removeEventListener('webkitendfullscreen', onExitIOSFullscreen);
      }
    }, [enableIOSFullscreen, onExitIOSFullscreen]);

    const play = async () => {
      try {
        await videoElementRef.current?.play();
      } catch {
        // under iOS sometimes play throws and sometimes it just silently does not work
        // let's try to handle it in a single place(`finally`)
      } finally {
        // for some reason on iOS play does not work always.
        // Maybe it's somehow related to the fullscreen animation.
        // Maybe we could find a more bullet proof solution, but for now
        // seems like trying one more time with a little delay does the trick.
        if (enableIOSFullscreen && videoElementRef.current?.paused) {
          await new Promise((r) => setTimeout(r, 100));
          await videoElementRef.current?.play();
        }
      }

      setIsPlaying(true);

      onStarted?.();
    };

    const pause = () => {
      videoElementRef.current?.pause();

      setIsPlaying(false);
    };

    const togglePlay = async () => {
      if (!isPlaying) {
        await play();
      } else {
        pause();
      }
    };

    const getVideoElementNode = () => {
      const video = videoElementRef.current;
      if (!video) {
        throw new Error('`video` element is missing');
      }

      return video;
    };

    const updateProgress = () => {
      const video = getVideoElementNode();

      setCurrentTime(video.currentTime);

      // keep user active while video is playing
      if (!video.paused) {
        // restarts the idle timer
        idleTimer.start();
      }
    };

    const onLoadedMetadata = () => {
      const video = getVideoElementNode();

      setDuration(video.duration);
      setVolume(video.volume);
    };

    const onVideoVolumeChange = () => {
      const video = getVideoElementNode();

      setVolume(video.volume);
    };

    const changeVolume = (newVolume: number) => {
      const video = getVideoElementNode();

      video.volume = newVolume;
    };

    const changeProgress = (progress: number) => {
      const currentTime = (duration * progress) / 100;
      const video = getVideoElementNode();

      video.currentTime = currentTime;
      setCurrentTime(currentTime);
    };

    const keepUserActive = () => {
      setIsUserActive(true);

      if (inactivityTimerRef.current) {
        teardownInactivityTimer();
      }

      inactivityTimerRef.current = setTimeout(() => {
        setIsUserActive(false);
      }, USER_ACTIVITY_LIFETIME);
    };

    const _onEnded = () => {
      if (enableIOSFullscreen) {
        const video = videoElementRef.current;
        if (video && isIOSVideoElement(video)) {
          if (video.webkitDisplayingFullscreen) {
            video.webkitExitFullscreen();
          }
        }
      }

      onEnded?.();
    };

    const _onStarted = () => {
      if (enableIOSFullscreen) {
        const video = videoElementRef.current;
        if (video && isIOSVideoElement(video)) {
          setTimeout(() => {
            if (!video.webkitDisplayingFullscreen) {
              video.webkitEnterFullscreen();
            }
          }, 100);
        }
      }
    };

    useImperativeHandle(ref, () => {
      return {
        play,

        stop() {
          videoElementRef.current?.pause();

          if (videoElementRef.current) {
            videoElementRef.current.currentTime = 0;
          }

          setIsPlaying(false);
        },
        load() {
          videoElementRef.current?.load();
        },
      };
    });

    return (
      <div
        className={`${className} ${scope} ${scope}--${systemDevice}`}
        data-is-user-active={isUserActive}
        data-has-started={hasStarted || null}
        onMouseMove={keepUserActive}
        onClick={keepUserActive}
        onMouseDown={keepUserActive}
        onTouchStart={keepUserActive}
        onTouchMove={keepUserActive}
      >
        <video
          className={scope + '-video'}
          ref={videoElementRef}
          autoPlay={false}
          loop={loop}
          poster={previewSource}
          preload="metadata"
          onEnded={_onEnded}
          onPlay={_onStarted}
          onClick={togglePlay}
          onTimeUpdate={updateProgress}
          onLoadedMetadata={onLoadedMetadata}
          onVolumeChange={onVideoVolumeChange}
        >
          {/* The video doesn't show a preview on an iPhone when the "t" parameter is not present */}
          <source src={source} type="video/mp4" />
        </video>

        {!isPlaying && !hasStarted && previewSource ? (
          <div
            className={`${scope}-preview`}
            style={{
              backgroundImage: `url(${previewSource})`,
            }}
          ></div>
        ) : null}

        {!isPlaying ? (
          <button
            type="button"
            className={scope + '-playButton'}
            onClick={() => play()}
          >
            <SvgIcon className={scope + '-playIcon'} name={`play`} />
          </button>
        ) : null}

        {canShowControls === true && !enableIOSFullscreen ? (
          <div className={`${scope}-playbackControls`}>
            {isPlaying ? (
              <button
                type="button"
                className={scope + '-pauseButton'}
                onClick={() => pause()}
              >
                <SvgIcon className={scope + '-pauseIcon'} name={`pause`} />
              </button>
            ) : null}

            <Volume
              className={`${scope}-volume`}
              level={volume}
              onChange={changeVolume}
            />

            <div className={`${scope}-timeControls`}>
              <Progress
                className={`${scope}-progress`}
                percent={progressPercent}
                onChangeRequest={changeProgress}
              />

              <div className={`${scope}-timing`}>
                <div className={`${scope}-currentTime`}>
                  {toMMSS(currentTime)}
                </div>
                <div className={`${scope}-totalTime`}>{toMMSS(duration)}</div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  },
);

VideoPlayer.displayName = 'VideoPlayer';

export default VideoPlayer;

const toMMSS = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds);

  return date.toISOString().substring(14, 19);
};

const isIOSVideoElement = (
  video: HTMLVideoElement | iOSVideoElement,
): video is iOSVideoElement => {
  return (
    video &&
    'webkitSupportsFullscreen' in video &&
    video.webkitSupportsFullscreen
  );
};

interface iOSVideoElement extends HTMLVideoElement {
  webkitSupportsFullscreen: boolean;
  webkitDisplayingFullscreen: boolean;
  webkitExitFullscreen: () => void;
  webkitEnterFullscreen: () => void;
}
