import ConfigurableLink from '../../components/ConfigurableLink/ConfigurableLink';
import { showCookieSettingsModal } from '../../helpers/showCookieSettingsModal';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';

interface Args {
  scope: string;
  className?: string;
}

const CookiePolicyDeutsch = ({ scope, className = '' }: Args) => {
  const systemDevice = useSystemDevice();
  const isVaha = systemDevice === SYSTEM_DEVICE.VAHA;

  return (
    <>
      <h1 className={`${scope}-heading ${className}`}>Porsche Cookie Policy</h1>
      <div className={`${scope}-textContainer`}>
        <div className={`${scope}-boldText`}>1. Anwendungsbereich</div>
        <br />

        <div>
          Diese Cookie Policy gilt in Bezug auf die Webseiten von Porsche für
          den deutschen Markt, die von der Dr. Ing. h.c. F. Porsche AG angeboten
          werden, ergänzend zu den allgemeinen Datenschutzhinweisen für die
          einzelnen Webseiten und gibt Ihnen unter Berücksichtigung der Vorgaben
          aus der Datenschutzgrundverordnung (DSGVO) und aus dem
          Telekommunikation-Telemedien-Datenschutzgesetz (TTDSG) zusätzliche
          Informationen zu Art, Umfang und Zwecken der Datenverarbeitung bei
          eingesetzten Cookies und ähnlichen Technologien (nachfolgend zur
          Vereinfachung auch nur kurz „Cookies“).
        </div>
        <br />

        <div>
          Bitte beachten Sie, dass Ihre Einstellungen (Erteilung/Widerruf von
          Einwilligungen in den Einsatz von Cookies) in diesem Sinne für alle
          Webseiten von Porsche für den deutschen Markt gelten, so dass eine
          komfortable Nutzung gewährleistet ist. Insofern weisen wir darauf hin,
          dass diese Cookie Policy trotzdem nicht für alle Webseiten gilt, die
          Porsche betreffen. Insbesondere bei Webseiten anderer Porsche
          Gesellschaften bzw. für andere internationale Märkte und auf den
          Webseiten von Porsche Zentren bzw. Porsche Händler- und
          Servicebetrieben gelten eigene Einstellungsmöglichkeiten und ggf.
          abweichende Bestimmungen.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          2. Verantwortliche, Datenschutzbeauftragte, Betroffenenrechte
        </div>
        <br />

        <div>
          Der Einsatz von Cookies auf den Webseiten von Porsche für den
          deutschen Markt wird von verschiedenen Gesellschaften verantwortet,
          die die entsprechenden Webseiten anbieten. Verantwortlich für die
          Datenverarbeitung durch Cookies sind die folgenden Gesellschaften
          (nachfolgend zur Vereinfachung auch nur kurz „wir“):
        </div>
        <br />

        <div>
          Dr. Ing. h.c. F. Porsche AG, Porscheplatz 1, 70435 Stuttgart,
          Deutschland, Telefon: +49 711 911-0,{' '}
          <ConfigurableLink
            href="mailto:info@porsche.de"
            className={`${scope}-link`}
          >
            info@porsche.de
          </ConfigurableLink>
          , Kontakt zum Datenschutzbeauftragten unter vorstehender Postadresse
          oder unter{' '}
          <ConfigurableLink
            href="https://www.porsche.com/germany/privacy/contact/"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/germany/privacy/contact/
          </ConfigurableLink>
        </div>
        <br />

        <div>
          Als „Datenempfänger“ sind in den Detailansichten im Bereich „Ihre
          Cookie Einstellungen“ die Drittanbieter angegeben, die die
          Datenverarbeitung durch Cookies technisch umsetzen, damit Sie sich
          über die Hintergründe informieren können. Soweit Drittanbieter
          Auftragsverarbeiter oder im Einzelfall mit uns für die
          Datenverarbeitung gemeinsam verantwortlich sind, treffen wir mit den
          Drittanbietern entsprechende Regelungen bzw. organisieren die
          Datenverarbeitung entsprechend. Bitte beachten Sie, dass Drittanbieter
          die generierten Daten ggf. mit weiteren Daten zusammenführen und auch
          zu eigenen Zwecken nutzen können. <br></br>
          Informationen zu Ihren Betroffenenrechten bezüglich der Verwendung von
          Cookies entnehmen Sie bitte den allgemeinen Datenschutzhinweisen auf
          den Webseiten.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          3. Kategorien von personenbezogenen Daten
        </div>
        <br />

        <div>
          Wenn wir in dieser Porsche Cookie Policy die verarbeiteten
          Datenkategorien angeben, sind damit insbesondere die folgenden Daten
          gemeint: Stammdaten (z.B. Namen, Anschrift, Geburtsdaten),
          Kontaktdaten (z.B. E-Mail-Adressen, Telefonnummer, Messengerdienste),
          Inhaltsdaten (z.B. Texteingaben, Fotografien, Videos, Inhalte von
          Dokumenten/Dateien), Vertragsdaten (z.B. Vertragsgegenstand,
          Laufzeiten, Kundenkategorie), Zahlungsdaten (z.B. Bankverbindungen,
          Zahlungshistorie, Verwendung sonstiger Zahlungsdienstleister),
          Nutzungsdaten (z.B. Verlauf auf unserer Website, Nutzung bestimmter
          Inhalte, Zugriffszeiten, Kontakt- oder Bestellhistorie),
          Verbindungsdaten (z.B. Geräte-Informationen, IP-Adressen,
          URL-Referrer), Standortdaten (z.B. GPS-Daten, IP-Geolokalisierung,
          Zugriffspunkte), Diagnosedaten (z.B. Crash-Logs, Performance Daten der
          Website/App, andere technische Daten zur Analyse von Störungen und
          Fehlern).
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          4. Kategorien von Cookies und ähnlichen Technologien
        </div>
        <br />

        <div>
          Cookies sind Textdateien, die von den Webseiten auf Ihrem Endgerät
          abgelegt bzw. dort ausgelesen werden. Sie enthalten Buchstaben- und
          Zahlenkombinationen, um z.B. bei einer erneuten Verbindung zu den
          Cookie-setzenden Webseiten den Nutzer und seine Einstellungen
          wiederzuerkennen, das Eingeloggt-Bleiben in einem Kundenkonto zu
          ermöglichen oder ein bestimmtes Nutzungsverhalten statistisch zu
          analysieren.
        </div>
        <br />

        <div>
          Die WebStorage Technik ermöglicht es, Variablen und Werte lokal im
          Browser-Cache des Nutzers zu speichern. Die Technik umfasst sowohl den
          sog. &quot;sessionStorage&quot;, der bis zum Beenden des Browser-Tabs
          gespeichert bleibt, wie auch den &quot;localStorage&quot;, der so
          lange im Cache des Browsers gespeichert wird, bis der Cache vom Nutzer
          geleert wird. Die localStorage Technik ermöglicht es u.a., den Nutzer
          und seine Einstellungen bei dem Aufruf unserer Webseiten
          wiederzuerkennen.
        </div>
        <br />

        <div>
          Der Zugriff auf ein Cookie/ähnliche Technologien ist grundsätzlich nur
          von der Internetadresse aus möglich, von der diese Technologie gesetzt
          wird. Das heißt wir haben keinen Zugriff auf die Cookies der
          eingesetzten Anbieter. Diese haben keinen Zugriff auf unsere Cookies.
          Dritte haben weder auf unsere Cookies noch auf die der eingesetzten
          Anbieter Zugriff. Ein Zugriff durch Dritte kann nur durch technische
          Angriffe erlangt werden, die wir nicht kontrollieren können und für
          die wir nicht verantwortlich sind.
        </div>
        <br />

        <div>
          Pixel-Tags sind kleine Grafikdateien, die beim Aufruf unserer Webseite
          von den Servern des jeweiligen Dienstanbieters geladen werden. Hierbei
          wird auch der Programmcode geladen, durch den Daten erfasst werden
          (z.B. bezüglich Ihres Nutzungsverhaltens auf unseren Webseiten).
        </div>
        <br />

        <div>
          Cookies und ähnliche Technologien können es ermöglichen, dass
          Informationen in Ihrem Endgerät gespeichert werden bzw. auf in Ihrem
          Endgerät gespeicherte Informationen zugegriffen wird. Die auf unseren
          Webseiten eingesetzten Cookies lassen sich dabei in verschiedene
          Kategorien einteilen. Während technisch erforderliche Cookies stets
          beim Besuch unserer Webseiten abgelegt werden, haben Sie bei den
          anderen Kategorien die Möglichkeit selbst festzulegen, ob Sie den
          Einsatz von Cookies zulassen möchten oder nicht. Sie können Ihre
          Einstellungen jederzeit ändern. Bitte beachten Sie, dass die
          Blockierung bestimmter Arten von Cookies zu einer beeinträchtigten
          Erfahrung unserer Webseite und/oder der darüber abrufbaren Dienste und
          Funktionen führen kann.
        </div>
        <br />

        <div>
          Allgemeine Informationen zu den eingesetzten Cookies entnehmen Sie
          bitte den nachstehenden Abschnitten und Detailansichten („Ihre Cookie
          Einstellungen“).
        </div>
        <br />

        {!isVaha && (
          <>
            <div className={`${scope}-boldText`}>
              Ihre Cookie Einstellungen auf dieser Webseite
            </div>
            <br />
            <div id="app-uc-embed-place"></div>
          </>
        )}

        <div className={`${scope}-boldText`}>
          Technisch erforderliche Cookies und ähnliche Technologien
        </div>
        <br />

        <div>
          Der Einsatz von technisch erforderlichen Cookies und ähnlichen
          Technologien ist unbedingt erforderlich, um die von Ihnen aufgerufene
          Website ordnungsgemäß und sicher mit den für diesen Dienst typischen
          Funktionalitäten zur Verfügung zu stellen. Diese Technologien werden
          beispielsweise genutzt, um
        </div>

        <div>
          <ul>
            <li>Grundfunktionen der Webseite zu ermöglichen,</li>
            <li>die Einstellung Ihrer Datenschutzpräferenzen zu speichern,</li>
            <li>
              eine sichere Authentifizierung bereitzustellen, mit der Sie sich
              in Ihr Benutzerkonto einloggen können,
            </li>
            <li>
              Ihnen Multimedia-Inhalte bereitzustellen, die den technischen
              Voraussetzungen Ihres Endgeräts entsprechen sowie
            </li>
            <li>das Ausfüllen von Formularen zu ermöglichen.</li>
          </ul>
        </div>

        <div>
          Die Datenverarbeitung mittels technisch erforderlicher Cookies und
          ähnlicher Technologien erfolgt – sofern ein entsprechendes
          Vertragsverhältnis zugrunde liegt – auf Basis von Artikel 6 Absatz 1
          Buchstabe b) DSGVO oder auf Basis von Artikel 6 Absatz 1 Buchstabe c)
          DSGVO – sofern zur Erfüllung einer Rechtspflicht erforderlich – und im
          Übrigen aufgrund unseres berechtigten Interesses nach Artikel 6 Absatz
          1 Buchstabe f) DSGVO. Unser berechtigtes Interesse ergibt sich dabei
          aus den dargestellten Nutzungszwecken der jeweiligen Technologie sowie
          der Sicherstellung des technischen Betriebs unserer Webseite mit
          bestimmten, grundlegenden Funktionalitäten. Diese Technologien kommen
          automatisch bei Aufruf unserer Webseite oder einer bestimmten Funktion
          zum Einsatz, es sei denn, Sie haben das durch Einstellungen in Ihrem
          Endgerät und/oder Internetbrowser verhindert. Im Einzelnen setzen wir
          nachfolgende Dienste ein, die technisch erforderliche Cookies oder
          ähnliche Technologien verwenden:
        </div>
        <br />
        <div className={`${scope}-boldText`}>
          Usercentrics Consent Management Platform
        </div>
        <div>
          Dies ist ein Dienst zur Verwaltung Ihrer Einwilligungseinstellungen.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          Usercentrics Consent Management Platform + Bridge
        </div>
        <div>
          Dies ist ein Consent Management Service um Ihre Einwilligung über
          mehrere Seiten hinweg zu zu registrieren. Wir können damit
          nachvollziehen, welcher User die Einwilligung erteilt/nicht
          erteilt/widerrufen hat, sodass das Consent Banner bei einem Wechsel
          der (Sub-)Domain innerhalb einer Setting ID nicht erneut angezeigt
          wird.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          Weitere essenzielle Funktionen
        </div>
        <div>Essenzielle Funktionen für die Bereitstellung der Website</div>
        <br />
        <div className={`${scope}-boldText`}>Analyse und Performance</div>
        <div>
          Cookies und ähnliche Technologien werden zur Analyse von Reichweite
          und Performance eingesetzt, um eine Auswertung der Webseitennutzung zu
          ermöglichen. Diese Technologien werden teilweise durch die im
          Folgenden genannten Drittanbietern gesetzt. Durch den Einsatz solcher
          Technologien können wir beispielsweise die Besuche unserer Webseite
          zählen und nachvollziehen, von welchen anderen Webseiten eine
          Weiterleitung auf unsere Webseite erfolgte. Wir können so
          herausfinden, welche Teile unserer Webseite am beliebtesten sind,
          welche am geringsten genutzt werden und auf welche Weise sich die
          Besucher auf unserer Webseite bewegen. Dadurch können wir insgesamt
          die Leistung unserer Webseite ermitteln und diese verbessern sowie
          Inhalte optimieren. Wir nutzen die Technologien beispielsweise um
        </div>

        <div>
          <ul>
            <li>
              nachzuvollziehen, welche Inhalte von Relevanz für die Nutzer sind
              und darauf basierend die Qualität unserer Webseite fortlaufend zu
              verbessern und die Nutzererfahrung zu optimieren,
            </li>
            <li>
              die Reichweite und den Erfolg von Marketingmaßnahmen zu messen,
            </li>
            <li>
              die Performance unserer Webseite zu messen und zu verbessern,
              sowie
            </li>
            <li>
              Inhalte der Website an die Bedürfnisse der Nutzer anzupassen.
            </li>
          </ul>
        </div>
        <br />
        <br />

        <div>
          Die Datenverarbeitung mittels Cookies und ähnlicher Technologien zur
          Analyse von Reichweite und Performance erfolgt dabei ausschließlich
          auf Basis Ihrer freiwilligen Einwilligung gemäß Artikel 6 Absatz 1
          Buchstabe a) DSGVO. Wenn Sie uns Ihre Einwilligung hierzu erteilen,
          werden die Technologien zur Analyse beim Aufruf unserer Webseite oder
          beim Abruf eines darüber bereitgestellten Angebots eingesetzt. Sie
          haben hier die Möglichkeit, Ihre Einwilligung entweder auf einzelne
          oder auf sämtliche Dienste in dieser Kategorie zu erstrecken. Sie
          können dort zudem eine erteilte Einwilligung jederzeit mit Wirkung für
          die Zukunft widerrufen. In Einzelnen setzen wir nachfolgende Dienste
          ein, die Cookies oder ähnliche Technologien zur Analyse von Reichweite
          und Performance verwenden:
        </div>
        <br />
        <div className={`${scope}-boldText`}>Google Analytics</div>
        <div>
          Dies ist ein Analyse-Dienst zur Optimierung unserer digitalen
          Angebote.
        </div>
        <br />

        <div className={`${scope}-boldText`}>5. Ort der Verarbeitung</div>
        <br />

        <div>
          Die Verarbeitung der personenbezogenen Daten, die mittels Cookies
          erhoben werden, erfolgt grundsätzlich in der Europäischen Union (EU)
          und im Europäischen Wirtschaftsraum (EWR) bzw. in
          Drittstaaten/Drittländern, für die die Europäische Kommission gemäß
          Artikel 45 Absatz 3 DSGVO ein angemessenes Datenschutzniveau
          entsprechend jenem unter der DSGVO festgestellt hat. Allerdings können
          insbesondere die eingesetzten Drittanbieter ihren Sitz in Drittstaaten
          wie den Vereinigten Staaten von Amerika (USA) haben, die kein der
          DSGVO entsprechendes Datenschutzniveau aufweisen, bzw. dort die Daten
          verarbeiten. In solchen Fällen beachten wir die Anforderungen der
          Artikel 44 ff. DSGVO und ergreifen nach Möglichkeit Maßnahmen, um das
          Datenschutzniveau durch entsprechende Garantien dem Standard der DSGVO
          anzupassen, insbesondere durch den Abschluss von
          Standarddatenschutzklauseln/Standardvertragsklauseln der Europäischen
          Kommission gemäß Artikel 46 Absatz 2 Buchstabe c) DSGVO. Insofern kann
          eine Übermittlung auch erfolgen, wenn dies gemäß Artikel 49 Absatz 1
          Buchstaben b) und c) DSGVO für die Erfüllung eines Vertrages bzw. zur
          Durchführung vorvertraglicher Maßnahmen erforderlich ist.
        </div>
        <br />

        <div>
          Soweit es sich um Cookies zur Analyse sowie zum (ggf.
          personalisierten) Marketing handelt, die nur auf Grundlage einer
          Einwilligung eingesetzt werden, beinhaltet die entsprechende
          Einwilligungserklärung gemäß Artikel 49 Absatz 1 Buchstabe a) DSGVO
          auch den Umstand, dass die Daten ggf. in Drittstaaten außerhalb der EU
          verarbeitet werden, die kein der DSGVO entsprechendes
          Datenschutzniveau aufweisen. Weitere Informationen zum Ort der
          Datenverarbeitung bei den eingesetzten Cookies entnehmen Sie bitte den
          nachstehenden Abschnitten und Detailansichten („Ihre Cookie
          Einstellungen“). Bitte beachten Sie im Zusammenhang mit der Abgabe der
          Einwilligung, dass wegen der abweichenden Rechtslage in den
          Drittstaaten latente Risiken bestehen können, dass übermittelte Daten
          möglicherweise nicht im Einklang mit der DSGVO verarbeitet werden
          (z.B. indem die Drittanbieter die Daten mit weiteren Daten
          zusammenführen und zu eigenen Zwecken nutzen und Sie ggf. keine
          Betroffenenrechte wie etwa auf Auskunft und Löschung dagegen ausüben
          können wie dies unter der DSGVO möglich wäre). Insbesondere könnten
          lokale Behörden des jeweiligen Drittstaats sich ggf. Zugriff auf die
          Daten verschaffen, ohne dass Ihnen dagegen effektiver Rechtsschutz
          oder durchsetzbare Rechte wie in der EU zur Verfügung stehen würden.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          6. Einwilligung sowie Möglichkeiten gegen die Verwendung von Cookies
          und ähnlichen Technologien
        </div>
        <br />

        <div>
          <button
            type="button"
            onClick={() => showCookieSettingsModal()}
            className={`${scope}-buttonLink`}
          >
            In den Einstellungen zu Cookies und ähnlichen Technologien
          </button>{' '}
          haben Sie die Möglichkeit, jeweils Ihre freiwillige Einwilligung in
          die Verwendung der Technologien zur Analyse sowie zum (ggf.
          personalisierten) Marketing und den damit ggf. verbundenen
          Übermittlungen in Drittstaaten einzeln oder generell zu erteilen und
          diese jederzeit für die Zukunft zu widerrufen.
        </div>
        <br />

        <div>
          Wenn Sie den Einsatz von bestimmten Technologien oder generell von
          Cookies nicht wünschen, können Sie stattdessen auch deren Speicherung
          auf Ihrem Endgerät durch entsprechende Einstellungen Ihres Endgeräts
          und/oder Internetbrowsers verhindern.
        </div>
        <br />

        <div>
          Sie können in Ihren Internetbrowser-Einstellungen „keine Cookies
          akzeptieren“ wählen. Die Verfahren zur Verwaltung und Löschung von
          Cookies in den Einstellungen Ihres Internetbrowsers entnehmen Sie
          bitte der Hilfe-Funktion Ihres Internetbrowsers. Außerdem können Sie
          sämtliche Cookies auch mittels kostenloser Internetbrowser-Add-ons,
          wie z.B. „Adblock Plus“ (
          <ConfigurableLink
            href="https://adblockplus.org/de/"
            target="_blank"
            className={`${scope}-link`}
          >
            adblockplus.org/de/
          </ConfigurableLink>
          ) in Kombination mit der „EasyPrivacy“-Liste (
          <ConfigurableLink
            href="https://easylist.to/"
            target="_blank"
            className={`${scope}-link`}
          >
            easylist.to
          </ConfigurableLink>
          ) oder „Ghostery“ (
          <ConfigurableLink
            href="https://www.ghostery.com/"
            target="_blank"
            className={`${scope}-link`}
          >
            ghostery.com
          </ConfigurableLink>
          ), blockieren. Zugriffe auf ähnliche Technologien wie
          WebStorage-Inhalte oder Pixel-Tags können hierdurch nicht in jedem
          Fall ausgeschlossen werden.
        </div>
        <br />

        <div>
          Gespeicherte Cookies können Sie in den Systemeinstellungen Ihres
          Endgeräts und/oder Internetbrowsers jederzeit löschen.
        </div>
        <br />

        <div>
          Bitte beachten Sie, dass die Funktionsfähigkeit und der
          Funktionsumfang unseres Angebots eingeschränkt sein können, sofern Sie
          Cookies oder ähnliche Technologien blockieren oder keine Einwilligung
          in deren Nutzung erteilen.
        </div>
      </div>
    </>
  );
};

export default CookiePolicyDeutsch;
