import ConfigurableLink from '../../components/ConfigurableLink/ConfigurableLink';

interface Args {
  scope: string;
}

const ImprintDeutsch = ({ scope }: Args) => {
  return (
    <>
      <h1 className={`${scope}-heading`}>Anbieter</h1>
      <div className={`${scope}-textContainer`}>
        <div className={`${scope}-boldText`}>Dr. Ing. h.c. F. Porsche AG</div>
        <div>Porscheplatz 1</div>
        <div>D-70435 Stuttgart</div>
        <br />
        <div>
          E-Mail:{' '}
          <ConfigurableLink
            className={`${scope}-link`}
            href="mailto:info@porsche.de"
          >
            info@porsche.de
          </ConfigurableLink>
        </div>
        <div>Tel: (+49) 0711 911-0</div>
        <br />

        <div>vertreten durch den Vorstand:</div>
        <div>Oliver Blume, Vorsitzender</div>
        <div>Lutz Meschke, stv. Vorsitzender</div>
        <div>Andreas Haffner</div>
        <div>Detlev von Platen</div>
        <div>Albrecht Reimold</div>
        <div>Barbara Frenkel</div>
        <div>Michael Steiner</div>
        <br />

        <div>Registergericht: Amtsgericht Stuttgart</div>
        <div>HRB-Nr. 730623</div>
        <div>USt.-Ident.-Nr. DE 147 799 625</div>
        <br />

        <div className={`${scope}-boldText`}>
          Verbraucherinformation gemäß Verordnung EU Nr. 524/2013
        </div>
        <br />

        <div>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit. Die Plattform finden Sie unter{' '}
          <ConfigurableLink
            href="http://ec.europa.eu/consumers/odr/"
            target="_blank"
            className={`${scope}-link`}
          >
            http://ec.europa.eu/consumers/odr/
          </ConfigurableLink>
        </div>
        <br />

        <div>
          Unsere Email-Adresse lautet:{' '}
          <ConfigurableLink
            href="mailto:info@porsche.de"
            className={`${scope}-link`}
          >
            info@porsche.de
          </ConfigurableLink>
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          Verbraucherinformation gemäß Verbraucherstreitbeilegungsgesetz
        </div>
        <br />

        <div>
          Die Dr. Ing. h.c. F. Porsche AG sind nicht bereit und verpflichtet, an
          Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
          teilzunehmen.
        </div>
        <br />

        <div className={`${scope}-boldText`}>Rechtlicher Hinweis</div>
        <br />

        <div>
          Die Texte, Bilder, Grafiken, Animationen, Video- und Audiodateien
          sowie alle weiteren Inhalte auf dieser Webseite unterliegen den
          gesetzlichen Bestimmungen des Urheberrechts und ggf. weiteren Rechten
          des geistigen Eigentums. Der geschützte Inhalt dieser Webseite darf
          ohne Einwilligung des Rechtsinhabers nicht vervielfältigt, verbreitet,
          wiedergegeben, öffentlich zugänglich gemacht oder sonst verwertet
          werden.
        </div>
        <br />

        <div>
          Änderungen von Konstruktion, Ausstattung und Lieferumfang sowie
          Abweichungen im Farbton sowie Irrtümer bleiben vorbehalten. Alle
          angegebenen Preise sind unverbindlich.
        </div>
      </div>
    </>
  );
};

export default ImprintDeutsch;
