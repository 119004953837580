import InlineSvg from 'react-inlinesvg';

interface Args {
  className?: string;
  name: string;
  onClick?: () => unknown;
}

export default function SvgIcon({ className, name, onClick }: Args) {
  return (
    // @ts-expect-error bad typings
    <InlineSvg
      className={className}
      src={`${process.env.PUBLIC_URL}/media/images/icons/${name}.svg`}
      onClick={onClick}
    />
  );
}
