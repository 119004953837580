import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toKebabCase } from '../../helpers/toKebabCase';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useGetCurrentPathWithoutLanguage } from '../../hooks/useGetCurrentPathWithoutLanguage';
import { useGetImagesFolder } from '../../hooks/useGetImagesFolder';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import { GalleryMedia } from '../../types/GalleryMedia';
import './GroupGallery.scss';

function getGroups(data: GalleryMedia[]) {
  const groups: string[] = [];
  for (const item of data) {
    for (const group of item.groups) {
      if (!groups.includes(group)) {
        groups.push(group);
      }
    }
  }
  return groups;
}

function getFilteredData(
  data: GalleryMedia[],
  selectedGroup?: string,
): GalleryMedia[] {
  return data.filter(
    (item) =>
      (!selectedGroup || item.groups.includes(selectedGroup)) && item.isVisible,
  );
}

export default function GroupGallery({ data }: { data: GalleryMedia[] }) {
  const scope = 'GroupGallery';
  const [groups] = useState(getGroups(data));
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>();
  const [filteredData, setFilteredData] = useState(
    getFilteredData(data, selectedGroup),
  );
  const { t } = useTranslation();
  const systemDevice = useSystemDevice();
  const customNavigate = useCustomNavigate();
  const getCurrentPath = useGetCurrentPathWithoutLanguage();
  const imagesFolder = useGetImagesFolder();

  const goToView = (item: GalleryMedia) => {
    customNavigate({ page: `${getCurrentPath()}/${toKebabCase(item.name)}` });
  };

  const setGroup = (newSelectedGroup?: string) => {
    if (newSelectedGroup === selectedGroup) {
      newSelectedGroup = undefined;
    }
    setSelectedGroup(newSelectedGroup);
    setFilteredData(getFilteredData(data, newSelectedGroup));
  };

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <div className={scope + '-groups'}>
        {groups.map((group) => (
          <button
            type="button"
            key={group}
            className={
              scope +
              '-group' +
              (group === selectedGroup ? ` ${scope}-group--selected` : '')
            }
            onClick={() => setGroup(group)}
          >
            {t(group)}
          </button>
        ))}
      </div>
      <div className={scope + '-grid'}>
        {filteredData.map((item, index) => (
          <button
            type="button"
            onClick={() => goToView(item)}
            className={scope + '-gridCell'}
            key={index}
            style={{
              backgroundImage: `url(${imagesFolder}/${item.image})`,
            }}
          >
            <h2 className={scope + '-gridCellTitle'}>{t(item.name)}</h2>
          </button>
        ))}
      </div>
    </div>
  );
}
