import i18next from 'i18next';
import { ElementRef, useCallback, useEffect, useRef, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Helmet } from 'react-helmet-async';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { IdleTimerContext, useIdleTimer } from 'react-idle-timer';
import { BrowserRouter } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import IdlePrompt, {
  NAME as IDLE_PROMPT_NAME,
} from './components/IdlePrompt/IdlePrompt';
import Main from './components/Main/Main';
import './App.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {
  IDLE_TIMEOUT,
  IdleContext,
  PROMPT_BEFORE_IDLE,
} from './context/IdleContext';
import { ModalContext } from './context/ModalContext';
import { SYSTEM_DEVICE, useSystemDevice } from './hooks/useSystemDevice';

type MainHandle = ElementRef<typeof Main>;

function App() {
  const scope = 'App';

  const { i18n } = useTranslation();
  const [language] = useState(i18n.language);
  const systemDevice = useSystemDevice();
  const [googleAnalyticsID] = useState(
    systemDevice === SYSTEM_DEVICE.VAHA
      ? process.env.REACT_APP_VAHA_GOOGLE_ANALYTICS_ID
      : process.env.REACT_APP_GOOGLE_ANALYTICS_ID,
  );
  const [idleTimeout, setIdleTimeout] = useState(IDLE_TIMEOUT);
  const [promptBeforeIdle, setPromptBeforeIdle] = useState(PROMPT_BEFORE_IDLE);

  const isVaha = systemDevice === SYSTEM_DEVICE.VAHA;

  const isShowGoogleAnalytics = useCallback(() => {
    return (
      googleAnalyticsID &&
      (isVaha ||
        (
          JSON.parse(localStorage.getItem('uc_gcm') || '{}') as {
            analyticsStorage?: string;
          }
        ).analyticsStorage === 'granted')
    );
  }, [googleAnalyticsID, isVaha]);

  const [showGoogleAnalytics, setShowGoogleAnalytics] = useState(
    isShowGoogleAnalytics(),
  );

  useEffect(() => {
    function onPermissionsUpdate() {
      setShowGoogleAnalytics(isShowGoogleAnalytics());
    }
    window.addEventListener('UC_GCM_UPDATE', onPermissionsUpdate, {
      capture: true,
    });
    return () => {
      window.removeEventListener('UC_GCM_UPDATE', onPermissionsUpdate);
    };
  }, [isShowGoogleAnalytics]);

  const [visibleModal, setVisibleModal] = useState('');

  const onPrompt = () => {
    setVisibleModal(IDLE_PROMPT_NAME);
  };

  const onIdle = () => {
    goHome();
  };

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptBeforeIdle,
    onPrompt,
    onIdle,
    disabled: !isVaha,
  });

  const keepUserActive = () => {
    setVisibleModal('');
    idleTimer.start();
  };

  const goHome = () => {
    mainRef.current?.goHome();
    setVisibleModal('');
  };

  const updateIdleTimerActivity = (pathname: string) => {
    // seems there is no such a property on the idleTimer instance,
    // so let's use the following heuristics
    const isActive =
      idleTimer.getRemainingTime() > 0 &&
      idleTimer.getRemainingTime() < IDLE_TIMEOUT;

    // allows for '/', '/en', '/de/'
    const isHome = pathname.split('/').filter(Boolean).length < 2;
    if (isHome) {
      if (isActive) {
        idleTimer.reset();
        idleTimer.pause();
      }
    } else if (!isActive) {
      idleTimer.start();
    }
  };

  const mainRef = useRef<MainHandle>(null);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <HelmetProvider>
          <ModalContext.Provider value={{ visibleModal, setVisibleModal }}>
            <IdleContext.Provider
              value={{
                idleTimeout,
                setIdleTimeout,
                promptBeforeIdle,
                setPromptBeforeIdle,
              }}
            >
              <IdleTimerContext.Provider value={idleTimer}>
                <div
                  className={`${scope} ${scope}--${systemDevice}`}
                  lang={language}
                >
                  <div
                    className={`${scope}-content ${
                      visibleModal ? `${scope}-blurred` : ''
                    }`}
                  >
                    <Header />
                    <Main
                      ref={mainRef}
                      onChangeLocation={updateIdleTimerActivity}
                    />
                    <Footer />
                  </div>
                  {/* <CookieBox /> */}

                  <div id={'modal-root'} />

                  <IdlePrompt cancel={keepUserActive} restartSession={goHome} />

                  {showGoogleAnalytics && (
                    <Helmet>
                      <script
                        async
                        src={`https://www.googletagmanager.com/gtag/js?id=${
                          googleAnalyticsID
                        }`}
                      ></script>
                      <script>
                        {`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());

gtag('config', '${googleAnalyticsID}');`}
                      </script>
                    </Helmet>
                  )}
                  {process.env.REACT_APP_ENV && !isVaha && (
                    <Helmet>
                      <script
                        src={`${process.env.PUBLIC_URL}/js/udg-uc-sdk.min.js`}
                        id="65UQOnxVwR3ZV3"
                        data-gpc="false"
                        data-language={language}
                        {...(process.env.REACT_APP_ENV !== 'production' && {
                          'data-version': 'preview',
                        })}
                      ></script>
                      <script>
                        {`
                          window.GlobalConsent = window.GlobalConsent || {};
                          window.GlobalConsent.DarkMode = false;
                          window.GlobalConsent.HideEssentialSDK = true;
                          window.addEventListener('ucInit', function (e) { usercentrics.updateLanguage('${language}'); });`}
                      </script>
                    </Helmet>
                  )}
                </div>
              </IdleTimerContext.Provider>
            </IdleContext.Provider>
          </ModalContext.Provider>
        </HelmetProvider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
