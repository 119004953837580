import { CSSProperties, ChangeEvent } from 'react';
import './Progress.scss';

interface Args {
  percent: number;
  className: string;
  onChangeRequest?: (percent: number) => void;
}

export default function Progress({
  percent,
  className,
  onChangeRequest,
}: Args) {
  const scope = 'Progress';

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChangeRequest?.(Number(e.target.value));
  };

  return (
    <div
      className={`${className} ${scope}`}
      style={
        {
          '--percent': percent,
        } as CSSProperties
      }
    >
      <div className={`${scope}-container`}>
        <div className={`${scope}-full`}></div>
        <div className={`${scope}-behind`}></div>
        <div className={`${scope}-progressHandle`}></div>

        {/*
          Standard range input is tricky to properly style, especially cross-browser.
          So we use custom HTML(above) to represent the progress in conjunction with a
          hidden input[range] placed precisely on top of our custom one,
          which is responsible for all the user interactions.
         */}
        <input
          type="range"
          min="0"
          max="100"
          value={percent}
          step="1"
          onInput={onChange}
          onChange={onChange}
        />
      </div>
    </div>
  );
}
