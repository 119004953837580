import { CSSProperties, ChangeEvent } from 'react';
import './Volume.scss';
import SvgIcon from '../SvgIcon/SvgIcon';

interface Args {
  level: number;
  className: string;
  onChange?: (volume: number) => void;
}

const Volume = ({ level, className, onChange }: Args) => {
  const scope = 'VideoPlayerVolume';

  // react typings do not support the input orient attribute
  // to workaround this limitation we spread it
  const ffOrientWorkaround = {
    orient: 'vertical',
  };

  const onInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(Number(e.target.value));
  };

  return (
    <div
      className={`${className} ${scope}`}
      style={
        {
          '--level': level,
        } as CSSProperties
      }
    >
      <div className={`${scope}-soundBar`}>
        <div className={`${scope}-scale`}>
          <div className={`${scope}-level`}></div>
        </div>

        {/*
          Standard range input is tricky to properly style, especially cross-browser.
          That's why we use custom HTML(`scale` above) to represent the volume in conjunction with a
          hidden input[range] placed precisely on top of the `scale`, which is responsible for
          all the user interactions.
         */}
        <input
          type="range"
          {...ffOrientWorkaround}
          min="0"
          max="1"
          value={level}
          step="0.05"
          onInput={onInput}
          onChange={onInput}
        />
      </div>

      <SvgIcon className={scope + '-soundIcon'} name={`volume`} />
    </div>
  );
};

export default Volume;
