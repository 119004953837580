import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import './QRContainer.scss';

interface Args {
  value: string;
  className?: string;
  text?: string;
}

const QRContainer = ({
  value,
  className = '',
  text = 'scan_for_mobile',
}: Args) => {
  const scope = 'QRContainer';

  const { t } = useTranslation();

  return (
    <div className={`${scope} ${className}`}>
      <QRCode
        value={value}
        size={170}
        className={`${scope}-qr`}
        level="L"
        bgColor="#000"
        fgColor="#fff"
      />
      {text && <div className={`${scope}-text`}>{t(text)}</div>}
    </div>
  );
};

export default QRContainer;
