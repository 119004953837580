import './AnimatedScroll.scss';
import SvgIcon from '../SvgIcon/SvgIcon';

const AnimatedScroll = () => {
  const scope = 'AnimatedScroll';

  return (
    <div className={`${scope} ${scope}`}>
      <SvgIcon name={'down-arrow'} className={`${scope}-downArrow`} />
      <SvgIcon name={'down-arrow'} className={`${scope}-downArrow`} />
      <SvgIcon name={'down-arrow'} className={`${scope}-downArrow`} />
    </div>
  );
};

export default AnimatedScroll;
