import { useTranslation } from 'react-i18next';
import './MeditationManual.scss';
import meditationManualData from '../../assets/json/meditation-manual.json';
import GroupGallery from '../../components/GroupGallery/GroupGallery';
import { useSystemDevice } from '../../hooks/useSystemDevice';

export default function MeditationManual() {
  const scope = 'MeditationManual';
  const systemDevice = useSystemDevice();
  const { t } = useTranslation();

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={scope + '-heading'}>{t('meditation_manual')}</h1>
      <p className={scope + '-description'}>
        {t('meditation_manual_description')}
      </p>
      <GroupGallery data={meditationManualData} />
    </div>
  );
}
