import { useTranslation } from 'react-i18next';
import Paragraph from '../../../components/Paragraph/Paragraph';
import { useGetImagesFolder } from '../../../hooks/useGetImagesFolder';
import { IndividualReadAndRechargeComponentPropsType } from './View';

export default function ReadAndRechargePage2({
  pageData,
  scope,
}: IndividualReadAndRechargeComponentPropsType) {
  const { t } = useTranslation();
  const imagesFolder = useGetImagesFolder();

  return (
    <>
      <div className={`${scope}-caption`}>{t(pageData.category)}</div>
      <div className={`${scope}-title`}>{t(pageData.title)}</div>
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_2_paragraph_1"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_2_paragraph_2"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_2_paragraph_3"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_2_paragraph_4"
      />
      <div className={`${scope}-cars`}>
        <div className={`${scope}-car`}>
          <img
            className={`${scope}-carImage`}
            src={`${imagesFolder}/readAndRecharge_article2_car1.jpg`}
            alt={'A retro car'}
          />
        </div>
        <div className={`${scope}-car`}>
          <img
            className={`${scope}-carImage`}
            src={`${imagesFolder}/readAndRecharge_article2_car2.jpg`}
            alt={'A modern car'}
          />
        </div>
      </div>
    </>
  );
}
