import { useTranslation } from 'react-i18next';
import Paragraph from '../../../components/Paragraph/Paragraph';
import QRContainer from '../../../components/QRContainer/QRContainer';
import { useGetImagesFolder } from '../../../hooks/useGetImagesFolder';
import { SYSTEM_DEVICE, useSystemDevice } from '../../../hooks/useSystemDevice';
import { IndividualReadAndRechargeComponentPropsType } from './View';

export default function ReadAndRechargePage3({
  pageData,
  scope,
}: IndividualReadAndRechargeComponentPropsType) {
  const { t } = useTranslation();
  const imagesFolder = useGetImagesFolder();
  const systemDevice = useSystemDevice();
  const isVaha = systemDevice === SYSTEM_DEVICE.VAHA;

  return (
    <>
      <div className={`${scope}-caption`}>{t(pageData.category)}</div>
      <div className={`${scope}-title`}>{t(pageData.title)}</div>
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_3_paragraph_1"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_3_paragraph_2"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_3_paragraph_3"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey={
          isVaha
            ? 'read_and_recharge_page_3_paragraph_4_vaha'
            : 'read_and_recharge_page_3_paragraph_4'
        }
      />
      {isVaha && (
        <QRContainer
          className={`${scope}-museumQrCode`}
          value={'https://porschemuseum.littleplanet.com/'}
          text=""
        />
      )}
      <div className={`${scope}-tile`}>
        <img
          src={`${imagesFolder}/readAndRecharge_article3_tile.jpg`}
          alt={'Red Car Image'}
          className={`${scope}-tileImage`}
        />
      </div>
    </>
  );
}
