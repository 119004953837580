import { useTranslation } from 'react-i18next';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import Image from '../Image/Image';
import { WaterDropColumnDetails } from './WaterDropSection';
import './WaterDropItem.scss';

interface Args {
  data: WaterDropColumnDetails;
}

const WaterDropItem = ({ data }: Args) => {
  const scope = 'WaterDropItem';

  const { t } = useTranslation();
  const systemDevice = useSystemDevice();

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <Image
        className={`${scope}-introImage`}
        name={data.introImage}
        alt={data.title}
      />
      <div className={`${scope}-title`}>{t(data.title)}</div>
      <div className={`${scope}-subtitle`}>{t(data.subtitle)}</div>
      <div className={`${scope}-gridContainer`}>
        <div className={`${scope}-pipe`} />
        <div className={`${scope}-connectingLines`} />
        {data.items.map((item) => {
          return (
            <div key={item.title} className={`${scope}-singleItem`}>
              <Image
                name={item.image}
                imageCustomPath={`${process.env.PUBLIC_URL}/media/images`}
                alt={t(item.title)}
                className={`${scope}-hydrationStationImage`}
              />
              <div className={`${scope}-item-title`}>{t(`${item.title}`)}</div>
              <div className={`${scope}-item-description`}>
                {t(item.description)}
              </div>
              <p className={`${scope}-itemLegal`}>{t(item.legal)}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default WaterDropItem;
