import { ElementRef, useMemo, useRef, useState } from 'react';
import './ExerciseVideo.scss';
import { useTranslation } from 'react-i18next';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import SvgIcon from '../SvgIcon/SvgIcon';
import VideoPlayer from '../VideoPlayer/VideoPlayer';

interface Args {
  source: string;
  introSource: string;
  previewSource: string;
}

type VideoPlayerHandle = ElementRef<typeof VideoPlayer>;

const SKIP_INTRO_LIFETIME = 5000;

export default function ExerciseVideo({
  source,
  introSource,
  previewSource,
}: Args) {
  const scope = 'ExerciseVideo';

  const { t } = useTranslation();
  const introVideoElementRef = useRef<VideoPlayerHandle>(null);
  const videoElementRef = useRef<VideoPlayerHandle>(null);
  const rootElementRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [fullscreenFailed, setFullscreenFailed] = useState(false);
  const systemDevice = useSystemDevice();

  const supportsCustomElementFullscreen = useMemo(
    () => _supportsCustomElementFullscreen(),
    [],
  );

  const [introsSeen, setIntrosSeen] = useLocalStorage<string[]>(
    'intros-seen',
    [],
  );

  const seenIntro = useMemo(
    () => introsSeen.includes(introSource),
    [introSource, introsSeen],
  );

  const canShowIntro = useMemo(
    () => supportsCustomElementFullscreen || !seenIntro,
    [seenIntro, supportsCustomElementFullscreen],
  );

  const [isIntro, setIsIntro] = useState(canShowIntro);

  const [shouldShowSkipIntro, setShouldShowSkipIntro] = useState(true);
  const skipIntroTimerRef = useRef<NodeJS.Timeout | null>();

  const onStartPlaying = async () => {
    if (!rootElementRef.current) {
      throw new Error('`ExerciseVideo` is missing');
    }

    try {
      // the handler is invoked for any of intro/main video is started.
      // That means when we play the main video we should do one of:
      // - Do nothing and keep playing in a fullscreen if intro has finished.
      //   If we request the fullscreen twice we'd have an undesired exception.
      // - request the fullscreen if the intro has been skipped as seen before
      if (!document.fullscreenElement) {
        await requestFullscreen(rootElementRef.current);

        document.addEventListener('fullscreenchange', onFullscreenChange);
      } else if (document.fullscreenElement !== rootElementRef.current) {
        throw new Error('Fullscreen is in use by an external element');
      }
    } catch (e) {
      setFullscreenFailed(true);
    } finally {
      setIsPlaying(true);

      setShouldShowSkipIntro(true);
      skipIntroTimerRef.current = setTimeout(() => {
        setShouldShowSkipIntro(false);
        skipIntroTimerRef.current = null;
      }, SKIP_INTRO_LIFETIME);
    }
  };

  const resetIntro = () => {
    if (skipIntroTimerRef.current) {
      clearTimeout(skipIntroTimerRef.current);
      skipIntroTimerRef.current = null;
    }
  };

  const onIntroEnded = async () => {
    resetIntro();

    setIsIntro(false);

    if (videoElementRef.current) {
      await videoElementRef.current?.play();
    }

    if (!seenIntro) {
      setIntrosSeen([...introsSeen, introSource]);
    }
  };

  const skipIntro = () => {
    introVideoElementRef.current?.stop();

    return onIntroEnded();
  };

  const onFullscreenChange = () => {
    const isFullscreen = Boolean(document.fullscreenElement);
    if (!isFullscreen) {
      return reset();
    }
  };

  const close = () => {
    reset();

    if (document.fullscreenElement) {
      return document.exitFullscreen();
    }
  };

  const reset = () => {
    document.removeEventListener('fullscreenchange', onFullscreenChange);

    introVideoElementRef.current?.stop();
    videoElementRef.current?.stop();

    setIsPlaying(false);
    resetIntro();

    if (canShowIntro) {
      setIsIntro(true);
    }
  };

  return (
    <div
      className={`${scope} ${scope}--${systemDevice}`}
      ref={rootElementRef}
      data-is-intro={isIntro || null}
    >
      <VideoPlayer
        className={scope + '-introVideo'}
        ref={introVideoElementRef}
        source={introSource}
        previewSource={previewSource}
        onEnded={onIntroEnded}
        onStarted={onStartPlaying}
        enableIOSFullscreen={fullscreenFailed}
        onExitIOSFullscreen={reset}
        canShowControls={isPlaying}
      />

      <VideoPlayer
        source={source}
        className={scope + '-video'}
        ref={videoElementRef}
        previewSource={previewSource}
        onEnded={close}
        onStarted={onStartPlaying}
        enableIOSFullscreen={fullscreenFailed}
        onExitIOSFullscreen={reset}
        canShowControls={isPlaying}
      />

      {isPlaying && !fullscreenFailed ? (
        <button className={`${scope}-close`} onClick={close}>
          <SvgIcon name="close" />
        </button>
      ) : null}

      {isPlaying && isIntro && shouldShowSkipIntro && !fullscreenFailed ? (
        <button className={`${scope}-skipIntro`} onClick={skipIntro}>
          {t(`skip_intro`)}
        </button>
      ) : null}
    </div>
  );
}

interface HTMLElementWithVendorPrefixes extends HTMLElement {
  mozRequestFullScreen?: () => Promise<unknown>;
  webkitRequestFullscreen?: () => Promise<unknown>;
}

function requestFullscreen(element: HTMLElementWithVendorPrefixes) {
  if (element.requestFullscreen) {
    return element.requestFullscreen();
  } else if (element.mozRequestFullScreen) {
    return element.mozRequestFullScreen();
  } else if (element.webkitRequestFullscreen) {
    return element.webkitRequestFullscreen();
  }

  throw new TypeError('Failed to open fullscreen');
}

function _supportsCustomElementFullscreen() {
  const element = document.createElement(
    'div',
  ) as HTMLElementWithVendorPrefixes;

  return (
    typeof element.requestFullscreen === 'function' ||
    typeof element.mozRequestFullScreen === 'function' ||
    typeof element.webkitRequestFullscreen === 'function'
  );
}
