import React, { ReactNode, useContext } from 'react';
import './Modal.scss';
import { createPortal } from 'react-dom';
import { ModalContext } from '../../context/ModalContext';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import SvgIcon from '../SvgIcon/SvgIcon';

interface Args {
  children: ReactNode;
  name: string;
  onClose?: (...args: unknown[]) => unknown;
  className?: string;
}

const Modal = ({ name, onClose, children, className = '' }: Args) => {
  const scope = 'Modal';
  const systemDevice = useSystemDevice();
  const { visibleModal, setVisibleModal } = useContext(ModalContext);
  const modalRoot = document.getElementById('modal-root');
  const isModalVisible = visibleModal === name;

  const close = () => {
    if (onClose) {
      setVisibleModal('');
      onClose();
    }
  };

  if (!isModalVisible || !modalRoot) {
    return null;
  }

  return createPortal(
    <div className={`${scope} ${scope}--${systemDevice} ${className}`}>
      <div className={`${scope}-backdrop`} onClick={close}></div>
      <div className={`${scope}-box`}>
        {onClose && (
          <div className={`${scope}-closeIconContainer`}>
            <SvgIcon
              className={`${scope}-closeIcon`}
              name={`close`}
              onClick={close}
            />
          </div>
        )}
        <div className={`${scope}-body`}>{children}</div>
      </div>
    </div>,
    modalRoot,
  );
};

export default Modal;
