import { ReactNode } from 'react';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';

interface Args {
  href: string;
  children: ReactNode;
  className?: string;
  target?: string;
  rel?: string;
}

interface ExtraConfigs {
  rel?: string;
}

const ConfigurableLink = ({
  href,
  children,
  target = '_self',
  className = '',
}: Args) => {
  const systemDevice = useSystemDevice();

  const isVaha = systemDevice === SYSTEM_DEVICE.VAHA;

  if (isVaha) {
    return <>{children}</>;
  }

  const extraConfigs: ExtraConfigs = {};

  if (target === '_blank') {
    extraConfigs.rel = 'noreferrer';
  }

  return (
    <a href={href} target={target} className={className} {...extraConfigs}>
      {children}
    </a>
  );
};

export default ConfigurableLink;
