import { JSX, useCallback, useState } from 'react';
import './View.scss';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReadAndRechargeData from '../../../assets/json/read-and-recharge.json';
import QRContainer from '../../../components/QRContainer/QRContainer';
import { useGetCurrentUrlForQrCode } from '../../../hooks/useGetCurrentUrlForQrCode';
import { SYSTEM_DEVICE, useSystemDevice } from '../../../hooks/useSystemDevice';
import ReadAndRechargePage1 from './Page-1';
import ReadAndRechargePage2 from './Page-2';
import ReadAndRechargePage3 from './Page-3';

export type IndividualReadAndRechargeComponentPropsType = {
  pageData: PageData;
  scope: string;
};

function ReadAndRechargeView() {
  const scope = 'ReadAndRechargeView';
  const systemDevice = useSystemDevice();
  const { t } = useTranslation();
  const params = useParams();

  const pages: {
    [key: string]: (
      params: IndividualReadAndRechargeComponentPropsType,
    ) => JSX.Element | null;
  } = {
    'page-1': ReadAndRechargePage1,
    'page-2': ReadAndRechargePage2,
    'page-3': ReadAndRechargePage3,
  };

  const getPageData = useCallback(() => {
    return ReadAndRechargeData.find((item) => item.page === params.page);
  }, [params.page]);

  const [pageData] = useState(() => getPageData());
  const qrUrl = useGetCurrentUrlForQrCode();

  const pageName = params.page;

  if (!pageName) {
    return null;
  }

  const PageContent = pages[pageName];

  if (!pageData) {
    return null;
  }

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={scope + '-heading'}>{t('read_and_recharge')}</h1>
      <div className={scope + '-content'}>
        <PageContent pageData={pageData} scope={scope} />
      </div>

      {systemDevice === SYSTEM_DEVICE.VAHA && (
        <QRContainer value={qrUrl} className={`${scope}-qrCode`} />
      )}
    </div>
  );
}

export default ReadAndRechargeView;

interface PageData {
  category: string;
  displayFor?: string;
  page: string;
  tileImage: string;
  title: string;
}
