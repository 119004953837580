import { Settings } from 'react-slick';

export const useCarouselOptions = (): Settings => {
  return {
    dots: true,
    speed: 500,
    swipeToSlide: true,
    infinite: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    pauseOnFocus: true,
  };
};
