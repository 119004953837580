import { useTranslation } from 'react-i18next';
import ReadAndRechargeData from '../../assets/json/read-and-recharge.json';
import Paragraph from '../../components/Paragraph/Paragraph';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useGetImagesFolder } from '../../hooks/useGetImagesFolder';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import './ReadAndRecharge.scss';

export default function ReadAndRecharge() {
  const scope = 'ReadAndRecharge';
  const customNavigate = useCustomNavigate();
  const imagesFolder = useGetImagesFolder();
  const systemDevice = useSystemDevice();
  const { t } = useTranslation();

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={scope + '-heading'}>{t('read_and_recharge')}</h1>
      <div className={scope + '-content'}>
        <div className={`${scope}-tile`}>
          <img
            src={`${imagesFolder}/readAndRecharge_tile.jpg`}
            className={`${scope}-tileImage`}
            alt={'Car Interior'}
          />
        </div>
        <div className={`${scope}-caption`}>
          {t('read_and_recharge_caption')}
        </div>
        <div className={`${scope}-title`}>
          {t('read_and_recharge_dear_fans')}
        </div>
        <Paragraph
          className={`${scope}-paragraph`}
          translateKey="read_and_recharge_paragraph_1"
        />
        <Paragraph
          className={`${scope}-paragraph`}
          translateKey="read_and_recharge_paragraph_2"
        />
        <Paragraph
          className={`${scope}-paragraph`}
          translateKey="read_and_recharge_paragraph_3"
        />
        <Paragraph
          className={`${scope}-paragraph`}
          translateKey="read_and_recharge_paragraph_4"
        />
        <Paragraph
          className={`${scope}-paragraph`}
          translateKey="read_and_recharge_paragraph_5"
        />
        <div className={`${scope}-sections`}>
          {ReadAndRechargeData.map((dataItem) => {
            return (
              <button
                type="button"
                key={dataItem.title}
                className={`${scope}-section`}
                onClick={() => {
                  customNavigate({
                    page: `read-and-recharge/${dataItem.page}`,
                  });
                }}
              >
                <div className={`${scope}-preview`}>
                  <img
                    src={`${imagesFolder}/${dataItem.tileImage}`}
                    className={`${scope}-previewImage`}
                    alt={dataItem.title}
                  />
                </div>
                <div className={`${scope}-sectionDetails`}>
                  <div className={`${scope}-itemCategory`}>
                    {t(dataItem.category)}
                  </div>
                  <div className={`${scope}-itemTitle`}>
                    {t(dataItem.title)}
                  </div>
                  <div className={`${scope}-more`}>{t('more')}</div>
                </div>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
}
