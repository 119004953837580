import './HydrationStation.scss';
import { useTranslation } from 'react-i18next';
import WaterDropData from '../../assets/json/waterdrop.json';
import HydrationStationCollage from '../../components/HydrationStationCollage/HydrationStationCollage';
import Image from '../../components/Image/Image';
import WaterDropSection from '../../components/WaterDropSection/WaterDropSection';
import { useSystemDevice } from '../../hooks/useSystemDevice';

const HydrationStation = () => {
  const scope = 'HydrationStation';

  const systemDevice = useSystemDevice();
  const { t } = useTranslation();

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={`${scope}-heading`}>{t('hydration_station')}</h1>
      <Image
        name={`hydrationStation_intro1.jpg`}
        className={`${scope}-hydrationStationImage`}
        alt={'Hydration Station'}
      />
      <WaterDropSection
        data={WaterDropData}
        className={`${scope}-waterDropSection`}
      />
      <HydrationStationCollage />
      <p className={`${scope}-legal`}>{t('hydration_station_legal')}</p>
    </div>
  );
};

export default HydrationStation;
