import ConfigurableLink from '../../components/ConfigurableLink/ConfigurableLink';

interface Args {
  scope: string;
  className?: string;
}

const PrivacyPolicyEnglish = ({ scope, className = '' }: Args) => {
  return (
    <>
      <h1 className={`${scope}-heading ${className}`}>Privacy Policy</h1>
      <h2>Porsche Charging Lounge Website</h2>
      <br />
      <br />

      <div className={`${scope}-textContainer`}>
        {/* eslint-disable react/no-unescaped-entities */}
        <div>
          We, Dr. Ing. h.c. F. Porsche AG (hereinafter referred to as
          &quot;we&quot; or &quot;Porsche AG&quot;), are happy about your
          interest in our online service of our Porsche Charging Lounge{' '}
          <ConfigurableLink
            href="https://porsche.charging-lounges.com/en"
            target="_blank"
            className={`${scope}-link`}
          >
            https://porsche.charging-lounges.com/
          </ConfigurableLink>{' '}
          (hereinafter referred to as the &quot;Online Service&quot;). We take
          the protection of your personal data very seriously. Your personal
          data will be processed exclusively in accordance with the statutory
          provisions of data protection law, in particular the General Data
          Protection Regulation (hereinafter referred to as &quot;GDPR&quot;).
          With this Privacy Policy, we inform you about the processing of your
          personal data and about your rights as a data subject as affected in
          connection with the Online Service. For information on the processing
          of personal data in other areas, please refer to the respective
          specific privacy policies.
        </div>
        {/* eslint-enable react/no-unescaped-entities */}
        <br />
        <div>
          If we refer to this Privacy Policy from external social media
          profiles, the following explanations apply only insofar as the
          processing takes place in our area of responsibility and insofar as no
          more specific and therefore prior information on data protection is
          provided in the context of such social media profiles.
        </div>
        <br />
        <br />
        <div className={`${scope}-boldText`}>
          1. Controller and data protection officer
        </div>
        <br />
        <div>
          Responsible for the data processing as controller in terms of data
          protection law is:
        </div>
        <br />
        <div>Dr. Ing. h.c. F. Porsche AG</div>
        <div>Porscheplatz 1</div>
        <div>70435 Stuttgart</div>
        <div>Germany</div>
        <div>Phone: +49 (0) 711 911-0</div>
        <div>
          Email:{' '}
          <ConfigurableLink
            href="mailto:info@porsche.de"
            className={`${scope}-link`}
          >
            info@porsche.de
          </ConfigurableLink>
        </div>
        <br />
        <div>
          If you have any questions or suggestions regarding data protection,
          please feel free to contact us. You can reach our data protection
          officer as follows:
        </div>
        <br />
        <div>Dr. Ing. h.c. F. Porsche AG</div>
        <div>Data Protection Officer</div>
        <div>Porscheplatz 1</div>
        <div>70435 Stuttgart</div>
        <div>Germany</div>
        <div>
          Contact:{' '}
          <ConfigurableLink
            href="https://www.porsche.com/international/privacy/contact/"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/international/privacy/contact/
          </ConfigurableLink>
        </div>
        <br />

        <div>
          With regard to data processing in connection with the Online Service,
          we are jointly responsible as joint controllers with the Group
          Companies of Porsche AG listed below (if used in this Privacy Policy,
          “we” therefore also stands for that Group Companies that are jointly
          responsible). With respect to the joint processes, we jointly
          determine the purposes and means of processing. In an agreement on
          joint controllership pursuant to Article 26 GDPR, we have determined
          how the respective tasks and responsibilities in the processing of
          personal data are structured and who fulfils which data protection
          obligations. In particular, it was determined how an appropriate level
          of security and your rights as a data subject can be ensured, how the
          information duties under data protection law can be fulfilled jointly
          and how potential data protection incidents can be monitored. This
          also includes ensuring that reporting and notification obligations are
          fulfilled. In case you contact us, we will coordinate in accordance
          with the aforementioned agreement pursuant to Article 26 GDPR in order
          to respond to your inquiry and to guarantee your rights as a data
          subject.
        </div>
        <br />

        <div>Dr. Ing. h. c. F. Porsche AG</div>
        <div>Data Protection Officer</div>
        <div>Porscheplatz 1</div>
        <div>70435 Stuttgart</div>
        <div>Germany</div>
        <div>
          Contact:{' '}
          <ConfigurableLink
            href="https://www.porsche.com/international/privacy/contact/"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/international/privacy/contact/
          </ConfigurableLink>
        </div>
        <br />
        <br />
        <div className={`${scope}-boldText`}>2. Subject of data protection</div>
        <br />
        <div>
          The subject of data protection is the protection of personal data.
          This is all information relating to an identified or identifiable
          natural person (so-called data subject). This includes information
          such as name, postal address, email address or telephone number, but
          also other information that may be generated when using the Online
          Service, in particular information about the beginning, end and extent
          of use as well as the transmission of your IP address.
        </div>
        <br />
        <div className={`${scope}-boldText`}>
          3. Purposes and legal basis of data processing
        </div>
        <br />
        <div>
          In the following, you will find an overview of the purposes and legal
          basis of data processing in connection with the Online Service. In any
          case, we process personal data in accordance with the legal
          requirements, even if in individual cases a different legal basis
          should be relevant than that stated below.
        </div>
        <br />
        <div>
          The provision of personal data by you may be required by law or
          contract or may be necessary for the conclusion of a contract. We will
          point it out separately if you are obliged to provide personal data
          and what possible consequences the non-supply would then have (e.g. a
          loss of claims or our position not to provide the requested service
          without providing certain information). The use of the Online Service
          is generally possible without registration. The use of individual
          functions may require prior registration. Even if you use the Online
          Service without registration, personal data may still be processed.
        </div>
        <br />
        <div className={`${scope}-boldText`}>
          3.1. Performance of a contract and pre-contractual measures
        </div>
        <br />
        <div>
          We process your personal data if this is necessary for the performance
          of a contract to which you are a party or for the implementation of
          pre-contractual measures taken in response to your request. The data
          processing is based on Article 6 paragraph 1 letter b) GDPR. The
          purposes of processing include enabling the use of our specific
          products and services within the scope of the Online Service. Please
          also note the details in the respective documents describing our
          products and services further to this Privacy Policy.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          3.2. Compliance with legal obligations
        </div>
        <br />
        <div>
          We process your personal data to comply with legal obligations to
          which we are subject. The data processing is based on Article 6
          paragraph 1 letter c) GDPR. These obligations may arise, for example,
          from commercial, tax, money laundering, financial or criminal law. The
          purposes of the processing result from the respective legal
          obligation; as a rule, the processing serves the purpose of complying
          with state control and information obligations.
        </div>
        <br />
        <div className={`${scope}-boldText`}>
          3.3. Safeguarding of legitimate interests
        </div>
        <br />
        <div>
          We also process your personal data to pursue the legitimate interests
          of ourselves or third parties, unless your rights, which require the
          protection of your personal data, outweigh these interests. The data
          processing is based on Article 6 paragraph 1 letter f) GDPR. The
          processing to safeguard legitimate interests is carried out for the
          following purposes or to safeguard the following interests.
        </div>
        <ul>
          <li>
            Needs analysis and customer segmentation, e.g. calculation and
            evaluation of affinities, preferences and customer potential;
          </li>
          <li>Handling of non-contractual inquiries and concerns;</li>
          <li>
            Ensuring availability, operation and security of technical systems
            as well as technical data management;
          </li>
          <li>Answering and evaluation of contact requests and feedback.</li>
        </ul>
        <br />
        <div>3.3.1. Retrieval of the online offer</div>
        <br />
        <div>
          When you call up the Online Service, data relating to your end device
          and your use of the online offer are processed and stored in a
          so-called log file. This concerns in particular technical data such as
          date and time of access, duration of the visit, type of terminal
          device, operating system used, functions used, amount of data sent, IP
          address and referrer URL. We process this data to ensure technical
          operation and to determine and eliminate faults. In doing so, we
          pursue the interest of permanently ensuring technical operability. We
          do not use this data for the purpose of drawing conclusions about your
          person.
        </div>
        <br />
        <div>3.3.2. Studies and surveys</div>
        <br />
        <div>
          As part of the further development of products, services and support
          offers, you may be selected from time to time to participate in a
          study or survey when visiting our Online Service. Participation is
          voluntary. We process your personal data if this is necessary for the
          initiation, implementation and evaluation of a study or survey. This
          also includes the further processing of the results. The processing is
          carried out to generate insights into individual customer satisfaction
          or general preferences and ideas of (potential) customers. In this
          respect, please also refer to the description of the study or survey
          and the accompanying information beyond this Privacy Policy. We do not
          use the collected data for the purpose of drawing conclusions about
          your person or your identity. Data directly related to your identity
          will only be processed if you provide it on a voluntary basis as part
          of the study or survey. If a company other than Porsche AG is
          responsible for processing your data, this will be indicated
          accordingly. We may use agencies bound by instructions as order
          processors to carry out the studies and surveys (see Section 8).
        </div>
        <br />

        <div className={`${scope}-boldText`}>3.4. Consent</div>
        <br />
        <div>
          We process your personal data on the basis of corresponding consent.
          The data processing is based on Article 6 paragraph 1 letter a) GDPR.
          If you give your consent, it is always for a specific purpose; the
          purposes of processing are determined by the content of your
          declaration of consent. You may revoke any consent you have given at
          any time, without affecting the legality of the processing that has
          taken place on the basis of the consent until revocation.
        </div>
        <br />
        <div>
          If you have given your consent, the companies listed in the
          declaration of consent can use the data on this basis, e.g. for
          individual customer and prospective customer support and contact you
          for these purposes via the communication channels you have requested.
          Your data will be used in this context to offer you an inspiring brand
          and customer care experience with Porsche and to make communication
          and interaction with you as personal and relevant as possible. Which
          of your data is actually used for individual customer and prospective
          customer support depends in particular on which data has been
          collected on the basis of orders and consultations (e.g. when buying
          or servicing Porsche products) and which data you have provided (e.g.
          your personal interests) at the respective contact points (e.g. at the
          Porsche Center).
        </div>
        <br />

        <div className={`${scope}-boldText`}>3.5. Change of purpose</div>
        <br />
        <div>
          If we process your personal data for a purpose other than that for
          which the data was collected, beyond the scope of a corresponding
          consent or a mandatory legal basis, we will take into account, in
          accordance with Article 6 paragraph 4 GDPR, the compatibility of the
          original and the now pursued purpose, the nature of the personal data,
          the possible consequences of further processing for you and the
          guarantees for the protection of the personal data.
        </div>
        <br />
        <div className={`${scope}-boldText`}>3.6. Profiling</div>
        <br />
        <div>
          We do not carry out automated decision making or profiling in
          accordance with Article 22 GDPR. Profiling is only carried out to
          protect our legitimate interests as described above.
        </div>
        <br />
        <div className={`${scope}-boldText`}>
          4. Access authorizations in the end device
        </div>
        <br />
        <div>
          To the extent functions of the Online Service require the granting of
          authorization to access your end device (e.g. access to location data
          or photos), the granting of these authorizations is voluntary.
          However, if you wish to use the corresponding functions, you must
          grant the appropriate authorizations, otherwise you will not be able
          to use these functions. The permissions remain active as long as you
          have not reset them in your device by deactivating the respective
          setting.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          5. Cookies and comparable technologies
        </div>
        <br />
        <div>
          We use cookies and comparable technologies in connection with the
          Online Service which serve to communicate with your end device and
          exchange stored information (hereinafter collectively referred to as
          &quot;Cookies&quot;). These Cookies are primarily used to make the
          functions of the Online Service usable. General examples in which the
          use of Cookies is technically required in this sense are the storage
          of a language selection, login data or a shopping or watch list.
          Accordingly, technically required Cookies may be used by us to enable
          the processing described in section 3.1 and to ensure the proper and
          secure operation of the Online Service. The data processing is then
          carried out on the basis of Article 6 paragraph 1 letters b) and f)
          GDPR, as it is necessary to implement the functions you have selected
          or to protect our legitimate interest in the functionality of the
          Online Service.
        </div>
        <br />
        <div>
          Insofar as we should also use Cookies in order to analyse the use of
          the Online Service and to be able to target it to your interests and,
          if necessary, to provide you with interest-based content and
          advertisements, this is done exclusively on the basis of your
          voluntary consent in accordance with Article 6 paragraph 1 letter a)
          GDPR. You will then have the opportunity to make the appropriate
          settings within the Online Service via the consent management. You may
          revoke any consent you have given at any time with effect for the
          future. Further information on Cookies and their function in detail as
          well as on setting and revocation options can be found directly in the
          corresponding areas of the consent management. Please note that we
          only make available the consent management in the context of the
          Online Service if, in addition to the above-mentioned technically
          required Cookies, consent- based Cookies are to be used.
        </div>
        <br />
        <div>
          If you do not wish to use Cookies in general, you can also prevent
          their storage by adjusting the settings of your end device
          accordingly. Stored Cookies can be deleted at any time in the system
          settings of your terminal device. Please note that blocking certain
          types of Cookies can lead to impaired use of the Online Service.
        </div>
        <br />

        <div>
          Furthermore, we use other technologies which, like cookies, serve to
          ensure the secure and user-friendly usability of the website (e.g. by
          protecting against misuse or evaluating usage). Technically, these
          additional technologies differ from cookies, as they do not store any
          information on your end device or access information already stored
          there. Insofar as these additional technologies process data that is
          subject to data protection law (e.g. IP addresses), we process this
          data on the basis of Article 6 paragraph 1 letters b) and f) GDPR to
          provide the website, to ensure technical operation and for the purpose
          of identifying and eliminating faults. In doing so, we also pursue the
          interest of permanently ensuring the technical functionality of the
          website, improving its performance and optimising the user experience.
          When you access our website, this data is automatically processed.
          Without the provision of this data, you will not be able to use our
          website. We do not use this data for the purpose of drawing
          conclusions about your person or identity.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          6. Integrated third-party services
        </div>
        <br />
        <div>
          Insofar as we integrate services of other providers within the scope
          of the Online Service in order to provide you with certain content or
          functions (e.g. playing videos or route planning) and we process
          personal data in the process, this is done on the basis of Article 6
          paragraph 1 letters b) and f) GDPR. This is because the data
          processing is then necessary to implement the functions you have
          selected or to protect our legitimate interest in an optimal range of
          functions of the Online Service. Insofar as Cookies may be used within
          the scope of these third-party services, the statements under Section
          5 apply. Please also refer to the privacy policy of the respective
          third-party provider with regard to the third-party services.
        </div>
        <br />
        <div>
          Services of other providers which we integrate or to which we refer
          are provided by the respective third parties. We have no influence on
          the content and function of the third-party services and are generally
          not responsible for the processing of your personal data by their
          providers, unless the third-party services are completely designed on
          our behalf and then integrated by us on our own responsibility.
          Insofar as the integration of a third-party service results in us
          establishing joint processes with its provider, we will define with
          this provider in an agreement on joint controllership pursuant to
          Article 26 GDPR how the respective tasks and responsibilities in the
          processing of personal data are structured and who fulfils which data
          protection obligations. Insofar as Cookies are to be set on the basis
          of your consent, you will receive further information on the
          responsibility for setting these Cookies and any associated
          third-party services in the corresponding area of the consent
          management.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          7. Recipients of personal data
        </div>
        <br />
        <div>
          Within our company, only those persons who need your personal data for
          the respective purposes mentioned have access to it. Your personal
          data will only be passed on to external recipients if we have legal
          permission to do so or have your consent. Below you will find an
          overview of the corresponding recipients:
        </div>
        <ul>
          <li>
            Commissioned processors: Group companies or external service
            providers, for example in the areas of technical infrastructure and
            maintenance, which are carefully selected and reviewed. The
            processors may only use the data in accordance with our
            instructions.
          </li>
          <li>
            Public bodies: Authorities and state institutions, such as tax
            authorities, public prosecutors&lsquo; offices or courts, to which
            we (must) transfer personal data, e.g. to fulfil legal obligations
            or to protect legitimate interests.
          </li>
          <li>
            Private bodies: Group companies, Porsche sales companies (incl.
            companies offering services in the area of Porsche Connect and Smart
            Mobility), dealer and service operations, cooperation partners,
            service providers (not bound by instructions) or commissioned
            persons such as Porsche Centres and Porsche Service Centres,
            financing banks, credit agencies or transport service providers.
          </li>
        </ul>
        <br />

        <div className={`${scope}-boldText`}>8. Customer and prospect care</div>
        <br />

        <div>
          In the following, we would like to provide you with further data
          protection information on the implementation of customer and prospect
          care at Porsche. The measures serve to ensure customer and interested
          party-oriented support.
        </div>
        <br />

        <div className={`${scope}-boldText ${scope}-textWithLeftPadding`}>
          8.1. Joint customer and prospect care at Porsche
        </div>
        <br />

        <div>
          The measures mentioned under point 3 in the context of customer and
          prospect support (in particular service and support, implementation of
          legal requirements, needs analyses, individual support via the desired
          communication channels) are generally not carried out by the
          controller alone. Involved in the customer and prospect care are in
          addition to the Porsche Centre responsible, Porsche Deutschland GmbH
          as the importer, Dr. Ing. h. c. F. Porsche AG as manufacturer and its
          affiliated companies in the areas of financial and mobility services,
          digital services and lifestyle products. An up-to-date list of the
          participating companies with their contact details can be found here:{' '}
          <ConfigurableLink
            href="https://www.porsche.com/germany/joint-customer-care"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/germany/joint-customer-care
          </ConfigurableLink>
          .
        </div>
        <br />

        <div>
          By using a central platform, we avoid situations where information
          about your products, contact data and interests would not be available
          for your respective Porsche contact and where you would therefore have
          to be referred to another company. This also applies in the event that
          the operating entity of your Porsche Center changes. By exchanging and
          verifying data, we ensure that you receive the best possible service
          and advice. It goes without saying that only the companies involved
          that actually need access to your data for operational purposes have
          such access.
        </div>
        <br />

        <div>
          Joint customer and prospect care can lead to joint control in certain
          cases. For this reason, the companies involved have set out in an
          agreement pursuant to Article 26 GDPR how the respective tasks and
          responsibilities for processing personal data are structured and who
          fulfills which data protection obligations. In particular, it was
          determined how an appropriate level of security can be achieved and
          how data subject rights and data protection information obligations
          can be guaranteed. Porsche Deutschland GmbH is available to you as a
          central contact in addition to the other companies involved.
        </div>
        <br />

        <div className={`${scope}-boldText ${scope}-textWithLeftPadding`}>
          8.2. Individual customer and prospect care
        </div>
        <br />

        <div>
          Insofar as you have given your voluntary consent to individual
          customer and prospect care, contact data, support and contract data
          (e.g. on purchases, leasing or financing), service information as well
          as data on interests, vehicles and services or products used will be
          used by the companies involved in joint customer and prospect care to
          send you personally tailored information and offers on Porsche
          vehicles, services and other products, invitations to events and
          surveys on satisfaction and expectations via the desired communication
          channels and to create an individual customer profile.
        </div>
        <br />

        <div>
          The specific data used for this purpose depends on the information
          collected on the basis of orders and consultations or provided by you
          (e.g. in the consultation at the Porsche Centre or as part of your
          activities under your Porsche ID at My Porsche). The data may also
          originate from orders or purchases that are processed in cooperation
          with partners (e.g. insurance companies) and from which we then
          receive the information, if applicable. Insofar as corresponding
          permissions have been granted, other data sources may also be
          included. This may be data from the vehicle (e.g. on your driving
          behavior) or on the use of digital media (e.g. on website use). You
          will receive more detailed information on the merging of data when
          being asked for the corresponding permission.
        </div>
        <br />

        <div>
          In order to offer an inspiring brand and customer care experience with
          Porsche and to make communication and interaction as personal and
          relevant as possible, the aforementioned data is used for needs
          analyses and customer segmentation. On this basis, for example,
          affinities, preferences and potentials can be determined by the
          companies involved as part of individual customer and prospect care.
          Examples of such measures for individualization are classification
          numbers on your likely product interests and on your satisfaction. The
          corresponding information and analysis results are stored in your
          customer profile and are then available for the individual
          configuration of customer and prospect care. The personal analysis and
          individual allocation in a customer profile will only take place if
          you have given your voluntary consent to individual customer and
          prospect care. Individual customer and prospect care without these
          measures for optimization and personalization is not offered.
        </div>
        <br />

        <div>
          Without your consent, we will only use the aforementioned data in the
          context of customer and prospect care to carry out general evaluations
          based on the aggregated data of customers and prospects in order to
          optimize our offers and systems and align them with overarching
          interests. Please note that your data may also be evaluated beyond the
          scope of customer and prospect support; this will then be done on the
          basis of your specific consent or another legal basis.
        </div>
        <br />

        <div>
          When we send emails for individual customer and prospect care, we may
          use market standard technologies such as tracking pixels or
          click-through links. This allows us to analyze which or how many
          emails are delivered, rejected and/or opened. The latter is
          particularly done by means of tracking pixels. Measuring the opening
          rate of our emails by means of tracking pixels is not fully possible
          if you have deactivated the display of images in your email program.
          In this case, the email will not be displayed to you in full. However,
          it is still possible for us to track whether an email has been opened
          if you click on text or graphic links in the email. By using
          click-through links, we can analyze which links in our emails are
          clicked on and deduce what interest there is in certain topics. When
          clicking on the corresponding link, you are guided through our
          separate analysis server before calling up the target page. Based on
          the results of the analysis, we can make emails more relevant in the
          context of individual customer and prospect care, send them in a more
          targeted manner or prevent them from being sent. The sending of emails
          and the evaluation of their use will only take place if you have given
          your voluntary consent to individual customer and prospect care.
          Individual customer and prospect care without the described evaluation
          for optimization is not offered.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          9. Data processing in third countries
        </div>
        <br />
        <div>
          If a data transfer takes place to entities whose registered office or
          place of data processing is not located in a member state of the
          European Union, another state party to the Agreement on the European
          Economic Area or a state for which an adequate level of data
          protection has been determined by a decision of the European
          Commission, we will ensure prior to the transfer that either the data
          transfer is covered by a statutory permit, that guarantees for an
          adequate level of data protection with regard to the data transfer are
          in place (e.g., through the agreement of contractual warranties,
          officially recognized regulations or binding internal data protection
          regulations at the recipient), or that you have given your consent to
          the data transfer.
        </div>
        <br />
        <div>
          If the data is transferred on the basis of Articles 46, 47 or 49
          paragraph 1, subparagraph 2 GDPR, you can obtain from us a copy or
          reference to the availability of the guarantees for an adequate level
          of data protection in relation to the data transfer. Please use the
          information provided under Section 1.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          10. Storage duration, erasure of data
        </div>
        <br />
        <div>
          We store your personal data, if there is legal permission to do so,
          only as long as necessary to achieve the intended purposes or as long
          as you have not revoked your consent. In the event of an objection to
          processing, we will delete your personal data, unless further
          processing is still permitted by law. We will also delete your
          personal data if we are obliged to do so for other legal reasons.
          Applying these general principles, we will usually delete your
          personal data immediately
        </div>
        <ul>
          <li>
            after the legal permission has ceased to apply and provided that no
            other legal basis (e.g. commercial and tax law retention periods)
            intervenes. If the latter applies, we will delete the data after the
            other legal basis has ceased to apply;
          </li>
          <li>
            if your personal data is no longer required for the purposes we
            pursue and no other legal basis (e.g. commercial and tax law
            retention periods) intervenes. If the latter is the case, we will
            delete the data after the other legal basis has ceased to apply.
          </li>
        </ul>
        <br />
        <div className={`${scope}-boldText`}>11. Rights of data subjects</div>
        <br />
        <div>
          <span className={`${scope}-boldText`}>Right to access: </span>
          <span>
            You have the right to receive information about your personal data
            stored by us.
          </span>
        </div>
        <br />
        <div>
          <span className={`${scope}-boldText`}>
            Right to rectification and erasure:{' '}
          </span>
          <span>
            You can demand that we correct incorrect data and, if the legal
            requirements are met, delete your data.
          </span>
        </div>
        <br />
        <div>
          <span className={`${scope}-boldText`}>
            Restriction of processing:{' '}
          </span>
          <span>
            You can demand that we restrict the processing of your data,
            provided that the legal requirements are met.
          </span>
        </div>
        <br />
        <div>
          <span className={`${scope}-boldText`}>Data portability: </span>
          <span>
            If you have provided us with data on the basis of a contract or
            consent, you may, if the legal requirements are met, demand that the
            data you have provided us with are handed over in a structured,
            common and machine-readable format or that we transfer it to another
            controller.
          </span>
        </div>
        <br />
        <div className={`${scope}-boldText`}>
          Objection: You have the right to object at any time to data processing
          by us based on the safeguarding of legitimate interests for reasons
          arising from your particular situation. If you make use of your right
          to object, we will stop processing the data unless we can prove
          compelling reasons for further processing worthy of protection which
          outweigh your rights and interests.
        </div>
        <br />
        <div className={`${scope}-boldText`}>
          Objection to direct marketing: If we process your personal data for
          the purpose of direct marketing, you have the right to object to our
          processing of your data for this purpose at any time. If you exercise
          your right to object, we will stop processing your data for this
          purpose.
        </div>
        <br />
        <div className={`${scope}-boldText`}>
          Revocation of consent: If you have given us your consent to process
          your personal data, you can revoke it at any time with effect for the
          future. The legality of the processing of your data until revocation
          remains unaffected.
        </div>
        <br />
        <div>
          <span className={`${scope}-boldText`}>
            Right to lodge a complaint with a supervisory authority:{' '}
          </span>
          <span>
            You can also lodge a complaint with the competent supervisory
            authority if you believe that the processing of your data violates
            applicable law. You can contact the supervisory authority
            responsible for your place of residence or your country or the
            supervisory authority responsible for us.
          </span>
        </div>
        <br />
        <div>
          <span className={`${scope}-boldText`}>
            Your contact with us and the exercise of your rights:{' '}
          </span>
          <span>
            Furthermore, you can contact us free of charge if you have questions
            regarding the processing of your personal data and your rights as a
            data subject. Please contact us at{' '}
            <ConfigurableLink
              href="https://www.porsche.com/privacy-contact/"
              target="_blank"
              className={`${scope}-link`}
            >
              https://www.porsche.com/privacy-contact/
            </ConfigurableLink>{' '}
            or by letter mail to the address provided under Section 1. Please
            make sure that we can definitely identify you. If you revoke your
            consent, you can alternatively choose the contact method that you
            used when you gave your consent.
          </span>
        </div>
        <br />

        <div className={`${scope}-boldText`}>12. Effective date</div>
        <br />
        <div>
          The latest version of this Privacy Policy applies. Version 4 - This
          version dates from 26.07.2023
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyEnglish;
