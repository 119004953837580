import ConfigurableLink from '../../components/ConfigurableLink/ConfigurableLink';
import { showCookieSettingsModal } from '../../helpers/showCookieSettingsModal';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';

interface Args {
  scope: string;
  className?: string;
}

const CookiePolicyEnglish = ({ scope, className = '' }: Args) => {
  const systemDevice = useSystemDevice();
  const isVaha = systemDevice === SYSTEM_DEVICE.VAHA;

  return (
    <>
      <h1 className={`${scope}-heading ${className}`}>Porsche Cookie Policy</h1>
      <div className={`${scope}-textContainer`}>
        <div className={`${scope}-boldText`}>1. Scope</div>
        <br />

        <div>
          This cookie policy applies in addition to the general privacy policy
          for our website (date 30.06.2023) and provides extra information about
          the type, scope and purpose of data processing for cookies and
          cookie-like technologies (hereinafter, for the sake of simplicity,
          also referred to as &quot;cookies&quot;).
        </div>
        <br />
        <br />

        <div className={`${scope}-boldText`}>
          2. Controllers, data protection officers, data subject rights
        </div>
        <br />

        <div>
          For information on the controllers and their data protection officers
          as well as your rights as a data subject regarding the use of cookies,
          please refer to the general{' '}
          <ConfigurableLink
            href="/en/privacy-policy"
            target="_blank"
            className={`${scope}-link`}
          >
            privacy policy
          </ConfigurableLink>{' '}
          for our website.
        </div>
        <br />
        <br />

        <div className={`${scope}-boldText`}>
          3. Categories of personal data
        </div>
        <br />

        <div>
          When we refer to the categories of data processed in this Porsche
          Cookie Policy, we mean in particular the following data:
        </div>

        <div>
          <span className={`${scope}-boldText`}>master data</span> (e.g. names,
          addresses, dates of birth),{' '}
          <span className={`${scope}-boldText`}>contact data</span> (e.g. e-mail
          addresses, telephone numbers, messenger services),{' '}
          <span className={`${scope}-boldText`}>content data</span> (e.g. text
          entered, photos, videos, contents of documents/files),{' '}
          <span className={`${scope}-boldText`}>contractual data</span> (e.g.
          object of the contract, terms, customer categories),{' '}
          <span className={`${scope}-boldText`}>payment data</span> (e.g. bank
          details, payment history, use of other payment service providers),{' '}
          <span className={`${scope}-boldText`}>usage data</span> (e.g. history
          on our website, use of specific content, access times, contact or
          order history),{' '}
          <span className={`${scope}-boldText`}>connection data</span> (e.g.
          device information, IP addresses, URL referrer),{' '}
          <span className={`${scope}-boldText`}>location data</span> (e.g. GPS
          data, IP geo-localisation, access points),{' '}
          <span className={`${scope}-boldText`}>diagnostic data</span> (e.g.
          crash logs, performance data of the website/app, other technical data
          for the analysis of faults and errors).
        </div>
        <br />
        <br />

        <div className={`${scope}-boldText`}>
          4. Categories of cookies and similar technologies
        </div>
        <br />
        <div>
          <span className={`${scope}-boldText`}>Cookies</span> are text files
          that are stored or read by a website on your end device. They contain
          combinations of letters and numbers which enable us to recognise the
          user and user settings when the user returns to the website which set
          the cookie; they also enable the user to stay logged in to a customer
          account and allow us to statistically analyse a certain user
          behaviour.
        </div>
        <br />

        <div>
          The <span className={`${scope}-boldText`}>WebStorage technology</span>{' '}
          enables the local storage of variables and values in the user&lsquo;s
          browser cache. The technology includes so-called
          &lsquo;sessionStorage&lsquo; which remains stored until the browser
          tab is closed as well as &lsquo;localStorage&lsquo; which remains
          stored in the browser cache until the cache is emptied by the user.
          The localStorage technology enables, among other things, recognition
          of the user and user settings when the user comes back to our website.
        </div>
        <br />

        <div>
          Cookies/similar technologies can only be accessed from the Internet
          address where the cookie was set. This means that we cannot access the
          cookies set by the service providers involved. They cannot access our
          cookies either. Third parties cannot access our cookies or those of
          the involved service providers. Third parties may only gain access by
          technical attacks which we cannot control and for which we are not
          responsible.
        </div>
        <br />

        <div>
          <span className={`${scope}-boldText`}>Pixel tags</span> are small
          graphic files which are loaded from the severs of the respective
          service provider when a user accesses our website. This also includes
          loading of the program code which enables the collection of data (e.g.
          regarding your user behaviour while visiting our website).
        </div>
        <br />

        <div>
          The cookies used on our website can be divided into three categories:
          technically required cookies, cookies for the analysis of range and
          performance, and marketing cookies. While technically required cookies
          are always stored when you visit our website, you have the option to
          decide whether or not you wish to allow the use of cookies for the
          analysis of range and performance as well as marketing cookies. You
          can change your settings at any time. Please note that blocking
          certain types of cookies can impact on your experience of our website
          and/or the services and functions available there.
        </div>
        <br />

        <div>
          For general information on the cookies used, please refer to the
          sections and detailed views below (&lsquo;Your cookie settings on this
          website&lsquo;).
        </div>
        <br />

        {!isVaha && (
          <>
            <div className={`${scope}-boldText`}>
              Your cookie settings on this website
            </div>
            <br />
            <div id="app-uc-embed-place"></div>
          </>
        )}

        <div className={`${scope}-boldText`}>5. Location of Processing</div>
        <br />

        <div>
          The processing of personal data collected by means of cookies is
          generally carried out in the European Union (EU) and the European
          Economic Area (EEA) or in third countries for which the European
          Commission has confirmed an adequate level of data protection in
          accordance with Article 45 (3) GDPR. However, in particular the
          third-party providers used may have their registered office in third
          countries such as the United States of America (USA), which do not
          have a level of data protection corresponding to that under the GDPR,
          or process the data there. In such cases, we observe the requirements
          of Articles 44 et seq. GDPR and, where possible, take measures to
          bring the level of data protection in line with the standard of the
          GDPR by means of appropriate guarantees, in particular by concluding
          standard data protection clauses/standard contractual clauses as
          issued by the European Commission pursuant to Article 46 (2) (c) GDPR.
          In this respect, a transfer may also take place if this is necessary
          for the performance of a contract or the implementation of
          pre-contractual measures pursuant to Article 49 (1) (b) and (c) GDPR.
        </div>
        <br />

        <div>
          As far as cookies for analysis as well as for (potentially
          personalized) marketing are concerned, which are only used on the
          basis of consent, the corresponding declaration of consent pursuant to
          Article 49 (1) (a) GDPR also includes the fact that the data may be
          processed in third countries outside the EU, which do not have a level
          of data protection that complies with the GDPR. For more information
          on the location of processing for the cookies used, please refer to
          the sections and detailed views below (&quot;Your Cookie
          Settings&quot;). Please note in connection with giving consent that
          due to the different legal situation in the third countries, there may
          be latent risks that transferred data may not be processed in
          accordance with the GDPR (e.g., by the third party providers merging
          the data with other data and using it for their own purposes, and you
          may not be able to exercise data subject rights such as rights to
          access and deletion against it as would be possible under the GDPR).
          In particular, local authorities in the respective third country could
          gain access to the data without providing you with effective legal
          protection or enforceable rights as in the EU.
        </div>
        <br />
        <br />

        <div className={`${scope}-boldText`}>
          6. Consent and options for objecting to the use of cookies and similar
          technologies
        </div>
        <br />

        <div>
          <button
            type="button"
            onClick={() => showCookieSettingsModal()}
            className={`${scope}-buttonLink`}
          >
            In the settings for cookies and similar technologies
          </button>{' '}
          on our website, you have the option to voluntarily consent to the use
          of technologies for analysis as well as for marketing individually or
          in general and revoke them at any time for the future.
        </div>
        <br />

        <div>
          If you do not wish to use certain technologies or cookies in general,
          you can also prevent them from being stored on your end device by
          setting the appropriate settings on your end device and/or internet
          browser.
        </div>
        <div>
          You can select &lsquo;Do not accept cookies&lsquo; in your Internet
          browser settings. The process for managing and deleting cookies in
          your Internet browser settings is detailed in the help menu of your
          Internet browser. You can also block all cookies using free Internet
          browser add-ons such as &lsquo;Adblock Plus&lsquo; (
          <ConfigurableLink
            href="https://adblockplus.org/de/"
            target="_blank"
            className={`${scope}-link`}
          >
            adblockplus.org/de/
          </ConfigurableLink>
          ) in combination with the &lsquo;EasyPrivacy&lsquo; list (
          <ConfigurableLink
            href="https://easylist.to/"
            target="_blank"
            className={`${scope}-link`}
          >
            easylist.to
          </ConfigurableLink>
          ) or &lsquo;Ghostery&lsquo; (
          <ConfigurableLink
            href="https://www.ghostery.com/"
            target="_blank"
            className={`${scope}-link`}
          >
            ghostery.com
          </ConfigurableLink>
          ). Access to similar technologies such as WebStorage content or pixel
          tags cannot be excluded in every case.
        </div>
        <br />

        <div>
          Stored cookies can be deleted at any time in the system settings of
          your device and/or Internet browser.
        </div>
        <br />

        <div>
          Please note that the functionality and range of functions of our offer
          may be limited if you block cookies or similar technologies or do not
          give your consent to their use.
        </div>
      </div>
    </>
  );
};

export default CookiePolicyEnglish;
