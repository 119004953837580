import './PrivacyPolicyWrapper.scss';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants/language.const';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import CookiePolicyDeutsch from './CookiePolicyDeutsch';
import CookiePolicyEnglish from './CookiePolicyEnglish';
import PrivacyPolicyDeutsch from './PrivacyPolicyDeutsch';
import PrivacyPolicyEnglish from './PrivacyPolicyEnglish';

const PrivacyPolicyWrapper = () => {
  const scope = 'PrivacyPolicy';

  const systemDevice = useSystemDevice();
  const { i18n } = useTranslation();

  const LanguageDependentPrivacyPolicy =
    i18n.language === LANGUAGES[0]
      ? PrivacyPolicyDeutsch
      : PrivacyPolicyEnglish;
  const LanguageDependentCookiePolicy =
    i18n.language === LANGUAGES[0] ? CookiePolicyDeutsch : CookiePolicyEnglish;

  useEffect(() => {
    if (location.href.includes('#porsche-cookie-policy')) {
      document.getElementById('porsche-cookie-policy')?.scrollIntoView({
        block: 'start',
      });
    }
  });

  useEffect(() => {
    const appUcEmbedPlace = document.getElementById('app-uc-embed-place');
    const appUcEmbedContent = document.getElementById('app-uc-embed-content');
    if (appUcEmbedPlace && appUcEmbedContent) {
      appUcEmbedPlace.appendChild(appUcEmbedContent);
    }
    return () => {
      if (appUcEmbedContent) {
        document.body.appendChild(appUcEmbedContent);
      }
    };
  }, []);

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <LanguageDependentPrivacyPolicy scope={scope} />
      <div id="porsche-cookie-policy"></div>
      <LanguageDependentCookiePolicy
        scope={scope}
        className={`${scope}-midSection`}
      />
      <div className={`${scope}-gradient`} />
    </div>
  );
};

export default PrivacyPolicyWrapper;
