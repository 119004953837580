import './Imprint.scss';
import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants/language.const';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import ImprintDeutsch from './ImprintDeutsch';
import ImprintEnglish from './ImprintEnglish';

const Imprint = () => {
  const scope = 'Imprint';

  const systemDevice = useSystemDevice();
  const { i18n } = useTranslation();

  const LanguageDependentContent =
    i18n.language === LANGUAGES[0] ? ImprintDeutsch : ImprintEnglish;

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <LanguageDependentContent scope={scope} />
      <div className={`${scope}-gradient`} />
    </div>
  );
};

export default Imprint;
