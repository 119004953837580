import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import './WaterDropSection.scss';
import PairedImages from './PairedImages';

interface WaterDropColumnDetailsItem {
  image: string;
  title: string;
  description: string;
  legal: string;
}

export interface WaterDropColumnDetails {
  introImage: string;
  title: string;
  subtitle: string;
  items: WaterDropColumnDetailsItem[];
}

interface WaterDropColumn {
  title: string;
  description: string;
  page: string;
  details: WaterDropColumnDetails;
}

interface Args {
  data: WaterDropColumn[];
  className?: string;
}

const WaterDropSection = ({ data, className = '' }: Args) => {
  const systemDevice = useSystemDevice();
  const customNavigate = useCustomNavigate();
  const { t } = useTranslation();

  const scope = 'WaterDropSection';

  return (
    <div className={`${scope} ${scope}--${systemDevice} ${className}`}>
      <div className={`${scope}-container`}>
        {data.map((waterDropItem, index) => {
          return (
            <a
              key={index}
              className={`${scope}-singleItem`}
              onClick={() => {
                customNavigate({
                  page: `hydration-station/${waterDropItem.page}`,
                });
              }}
            >
              <div className={`${scope}-title`}>{t(waterDropItem.title)}</div>
              <PairedImages
                data={waterDropItem.details}
                className={`${scope}-pairedImages`}
              />
              <div className={`${scope}-description`}>
                {t(waterDropItem.description)}

                <div className={`${scope}-more`}>{t('more')}</div>
              </div>
            </a>
          );
        })}
      </div>
    </div>
  );
};

export default WaterDropSection;
