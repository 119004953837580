import './Main.scss';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { forwardRef, useEffect, useImperativeHandle } from 'react';
import { initReactI18next } from 'react-i18next';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import de from '../../assets/json/locale-de.json';
import en from '../../assets/json/locale-en.json';
import { LANGUAGES } from '../../constants/language.const';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useHomePath } from '../../hooks/useHomePath';
import BrainTeaser from '../../pages/BrainTeaser/BrainTeaser';
import BrainTeaserView from '../../pages/BrainTeaser/View/BrainTeaserView';
import Faq from '../../pages/Faq/Faq';
import Home, { BASE_HOME_PATH } from '../../pages/Home/Home';
import HydrationStation from '../../pages/HydrationStation/HydrationStation';
import HydrationStationView from '../../pages/HydrationStation/View/View';
import Imprint from '../../pages/Imprint/Imprint';
import MeditationManual from '../../pages/MeditationManual/MeditationManual';
import MeditationManualView from '../../pages/MeditationManual/View/View';
import MirrorMode from '../../pages/MirrorMode/MirrorMode';
import PrivacyPolicyWrapper from '../../pages/PrivacyPolicy/PrivacyPolicyWrapper';
import ReadAndRecharge from '../../pages/ReadAndRecharge/ReadAndRecharge';
import ReadAndRechargeView from '../../pages/ReadAndRecharge/View/View';
import ScentingStation from '../../pages/ScentingStation/ScentingStation';
import SoundWorld, {
  SOUND_WORLD_PATH,
} from '../../pages/SoundWorld/SoundWorld';
import StretchFlow from '../../pages/StretchFlow/StretchFlow';
import StretchFlowView from '../../pages/StretchFlow/View/View';
import AnimatedScrollWrapper from '../AnimatedScrollWrapper/AnimatedScrollWrapper';
import ScrollToTop from '../ScrollToTop/ScrollToTop';

const fallbackLng = LANGUAGES[0];

void i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng,
    supportedLngs: LANGUAGES,
    detection: {
      order: ['path'],
    },
    resources: {
      de: {
        translation: de,
      },
      en: {
        translation: en,
      },
    },
  });

interface Handle {
  goHome: () => void;
}

interface Args {
  onChangeLocation?: (pathname: string) => void;
}

export default forwardRef<Handle, Args>(function Main(
  { onChangeLocation },
  ref,
) {
  const scope = 'Main';

  const navigate = useCustomNavigate();
  const homePath = useHomePath();
  // it's impossible to invoke `useNavigate` in the App cause it must be called inside the `<Router`
  // so we expose the `navigate` method to the APP, so it can use it for the "Go Home" button of the
  // idle promt
  useImperativeHandle(ref, () => ({
    goHome: () => {
      return navigate({ page: homePath });
    },
  }));

  const location = useLocation();
  useEffect(() => {
    onChangeLocation?.(location.pathname);
  }, [location.pathname, onChangeLocation]);

  return (
    <main className={scope}>
      <ScrollToTop />
      <Routes>
        {LANGUAGES.map((language, index) => (
          <Route path={`/${language}/`} key={index}>
            {homePath === BASE_HOME_PATH && (
              <>
                <Route path={BASE_HOME_PATH} element={<Home />} />
                <Route path="stretching" element={<StretchFlow />} />
                <Route path="stretching/:view" element={<StretchFlowView />} />
                <Route path="meditations" element={<MeditationManual />} />
                <Route
                  path="meditations/:view"
                  element={<MeditationManualView />}
                />
                <Route path="scenting-station" element={<ScentingStation />} />
                <Route
                  path={'hydration-station'}
                  element={<HydrationStation />}
                />
                <Route
                  path={'hydration-station/:page'}
                  element={<HydrationStationView />}
                />
                <Route path="read-and-recharge" element={<ReadAndRecharge />} />
                <Route
                  path="read-and-recharge/:page"
                  element={<ReadAndRechargeView />}
                />
                <Route path="brain-teaser" element={<BrainTeaser />} />
                <Route
                  path="brain-teaser/:page"
                  element={<BrainTeaserView />}
                />
                <Route path="mirror-mode" element={<MirrorMode />} />
                <Route path="faq" element={<Faq />} />
              </>
            )}
            {homePath === SOUND_WORLD_PATH && (
              <>
                <Route path={SOUND_WORLD_PATH} element={<SoundWorld />} />
              </>
            )}
            <Route path="imprint" element={<Imprint />} />
            <Route path="privacy-policy" element={<PrivacyPolicyWrapper />} />
          </Route>
        ))}
        <Route
          path="/"
          element={
            <Navigate
              to={`/${fallbackLng}${window.location.search ?? ''}`}
              replace
            />
          }
        />
      </Routes>
      <AnimatedScrollWrapper />
    </main>
  );
});
