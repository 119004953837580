import { useTranslation } from 'react-i18next';
import ScentingStationData from '../../../assets/json/scenting-station.json';
import { useGetImagesFolder } from '../../../hooks/useGetImagesFolder';
import { useSystemDevice } from '../../../hooks/useSystemDevice';
import './ScentingStationSingleItem.scss';

interface Args {
  data: (typeof ScentingStationData)[0];
  className?: string;
}

const ScentingStationSingleItem = ({ data, className = '' }: Args) => {
  const scope = 'ScentingStationSingleItem';

  const systemDevice = useSystemDevice();
  const { t } = useTranslation();
  const imagesFolder = useGetImagesFolder();

  return (
    <div className={`${scope} ${scope}--${systemDevice} ${className}`}>
      <div className={`${scope}-title`}>{t(data.title)}</div>
      <div className={`${scope}-imageContainer`}>
        <img
          src={`${imagesFolder}/${data.image}`}
          className={`${scope}-image`}
          alt={data.title}
        />
      </div>
      <div className={`${scope}-description`}>{t(data.text)}</div>
    </div>
  );
};

export default ScentingStationSingleItem;
