import './IdlePrompt.scss';
import { useTranslation } from 'react-i18next';
import Modal from '../Modal/Modal';

interface Args {
  cancel: () => void;
  restartSession: () => void;
}

const NAME = 'idle-prompt';

export { NAME };

export default function IdlePrompt({ cancel, restartSession }: Args) {
  const scope = `IdlePrompt`;

  const { t } = useTranslation();

  return (
    <Modal name={NAME} onClose={cancel}>
      <div className={`${scope}-body`}>
        <span className={`${scope}-text`}>{t('missing_interaction')}</span>

        <button className={`${scope}-cancel`} onClick={cancel}>
          {t('i_am_still_using_it')}
        </button>
        <button className={`${scope}-redirect`} onClick={restartSession}>
          {t('go_home')}
        </button>
      </div>
    </Modal>
  );
}
