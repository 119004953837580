import './BrainTeaserView.scss';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import BrainTeaserData from '../../../assets/json/brain-teaser.json';
import Modal from '../../../components/Modal/Modal';
import Paragraph from '../../../components/Paragraph/Paragraph';
import QRContainer from '../../../components/QRContainer/QRContainer';
import { SECONDS_BEFORE_SUGGESTING_NEXT_PUZZLE } from '../../../constants/timing.const';
import { ModalContext } from '../../../context/ModalContext';
import { useCustomNavigate } from '../../../hooks/useCustomNavigate';
import { useGetCurrentPathWithoutLanguage } from '../../../hooks/useGetCurrentPathWithoutLanguage';
import { useGetCurrentUrlForQrCode } from '../../../hooks/useGetCurrentUrlForQrCode';
import { useGetImagesFolder } from '../../../hooks/useGetImagesFolder';
import { SYSTEM_DEVICE, useSystemDevice } from '../../../hooks/useSystemDevice';

const BrainTeaserView = () => {
  const scope = 'BrainTeaserView';
  const modalName = 'brainTeaserModal';

  const { setVisibleModal } = useContext(ModalContext);

  const systemDevice = useSystemDevice();
  const params = useParams();
  const { t } = useTranslation();
  const customNavigate = useCustomNavigate();
  const getCurrentPath = useGetCurrentPathWithoutLanguage();
  const imagesFolder = useGetImagesFolder();
  const qrUrl = useGetCurrentUrlForQrCode();

  const getPuzzleData = useCallback(() => {
    const itemIndex = BrainTeaserData.findIndex(
      (data) => data.page === params.page,
    );
    const currentPuzzleData = BrainTeaserData[itemIndex];

    const nextPuzzleIndex =
      itemIndex === BrainTeaserData.length - 1 ? 0 : itemIndex + 1;
    const nextPuzzleData = BrainTeaserData[nextPuzzleIndex];

    return {
      currentPuzzleData,
      nextPuzzlePage: nextPuzzleData.page,
      nextPuzzleTitle: nextPuzzleData.title,
    };
  }, [params.page]);

  const handleButtonClick = (buttonIndex: number) => {
    if (activeButtonIndex !== buttonIndex) {
      setActiveButtonIndex(buttonIndex);
    } else {
      setActiveButtonIndex(undefined);
    }
  };

  const [currentPuzzleData, setCurrentPuzzleData] =
    useState<(typeof BrainTeaserData)[0]>();
  const [nextPuzzlePage, setNextPuzzlePage] = useState('');
  const [nextPuzzleTitle, setNextPuzzleTitle] = useState('');
  const [activeButtonIndex, setActiveButtonIndex] = useState<number>();
  const [shouldStartCountdownTimer, setShouldStartCountdownTimer] =
    useState(false);

  const solutionContent = useMemo(() => {
    if (!currentPuzzleData) {
      return [
        {
          title: '',
          text: '',
        },
      ];
    }

    return [
      {
        title: 'brain_teaser_hint',
        text: currentPuzzleData.hint,
      },
      {
        title: 'brain_teaser_solution',
        text: currentPuzzleData.solution,
      },
    ];
  }, [currentPuzzleData]);

  const navigateToNextQuestion = useCallback(
    (page: string) => {
      const currentPath = getCurrentPath();
      const currentPathWithoutPuzzle = currentPath.slice(
        0,
        currentPath.lastIndexOf('/'),
      );

      setVisibleModal('');
      customNavigate({ page: `${currentPathWithoutPuzzle}/${page}` });
    },
    [customNavigate, getCurrentPath, setVisibleModal],
  );

  useEffect(() => {
    const data = getPuzzleData();

    setCurrentPuzzleData(data.currentPuzzleData);
    setNextPuzzleTitle(data.nextPuzzleTitle);
    setNextPuzzlePage(data.nextPuzzlePage);

    setActiveButtonIndex(undefined);
    setShouldStartCountdownTimer(false);
  }, [getPuzzleData]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (shouldStartCountdownTimer) {
      timeoutId = setTimeout(() => {
        setVisibleModal(modalName);
      }, SECONDS_BEFORE_SUGGESTING_NEXT_PUZZLE * 1000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [setVisibleModal, shouldStartCountdownTimer]);

  if (!currentPuzzleData) {
    return null;
  }

  const questionParagraphs = Array.isArray(currentPuzzleData.question)
    ? currentPuzzleData.question
    : [currentPuzzleData.question];

  const isHintActive = activeButtonIndex === 0;
  const isAnswerActive = activeButtonIndex === 1;

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={`${scope}-heading`}>{t('brain_teaser')}</h1>
      <div className={`${scope}-question`}>
        <div className={`${scope}-title`}>
          {t(currentPuzzleData.page_title)}
        </div>
        {questionParagraphs.map((paragraph, index) => (
          <Paragraph
            key={index}
            className={`${scope}-paragraph`}
            translateKey={paragraph}
          />
        ))}
      </div>
      <div className={`${scope}-buttons`}>
        <div
          className={`${scope}-button ${
            isHintActive ? `${scope}-button--active` : ''
          }`}
          onClick={() => handleButtonClick(0)}
        >
          {t('brain_teaser_hint')}
        </div>
        <div
          className={`${scope}-button ${
            isAnswerActive ? `${scope}-button--active` : ''
          }`}
          onClick={() => {
            handleButtonClick(1);
            setShouldStartCountdownTimer(true);
          }}
        >
          {t('brain_teaser_solution')}
        </div>
      </div>
      {activeButtonIndex !== undefined && (
        <div className={`${scope}-solution`}>
          <div className={`${scope}-solutionBackground`}></div>
          <div className={`${scope}-solutionBody`}>
            <div className={`${scope}-solutionTitle`}>
              {t(solutionContent[activeButtonIndex].title)}
            </div>
            <Paragraph
              className={`${scope}-solutionText`}
              translateKey={solutionContent[activeButtonIndex].text}
            />
            {currentPuzzleData.solutionImage && isAnswerActive && (
              <div className={`${scope}-solutionTile`}>
                <img
                  className={`${scope}-solutionTileImage`}
                  src={`${imagesFolder}/${currentPuzzleData.solutionImage}`}
                  alt={`${currentPuzzleData.title}`}
                />
              </div>
            )}
          </div>
        </div>
      )}
      {activeButtonIndex === undefined && (
        <div className={`${scope}-tile`}>
          <img
            className={`${scope}-tileImage`}
            src={`${imagesFolder}/${currentPuzzleData.puzzlePageImage}`}
            alt={`${currentPuzzleData.title}`}
          />
        </div>
      )}

      {systemDevice === SYSTEM_DEVICE.VAHA && (
        <QRContainer value={qrUrl} className={`${scope}-qrCode`} />
      )}
      <Modal
        name={modalName}
        onClose={() => setVisibleModal('')}
        className={`${scope}-modal ${scope}-modal--${systemDevice}`}
      >
        <div className={`${scope}-modalBody`}>
          <div className={`${scope}-modalText`}>
            {t('brain_teaser_try_other_puzzles_question')}
          </div>
          <div
            className={`${scope}-modalButton`}
            onClick={() => {
              navigateToNextQuestion(nextPuzzlePage);
            }}
          >
            {t(nextPuzzleTitle)}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BrainTeaserView;
