import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import AnimatedScroll from './AnimatedScroll';

const SecondsToKeepAnimationVisible = 5;
const PagesWithAnimatedScrollEnabled = [
  'hydration-station',
  'scenting-station',
  'read-and-recharge',
];

const AnimatedScrollWrapper = () => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  const { pathname } = useLocation();
  const systemDevice = useSystemDevice();
  const isVaha = systemDevice === SYSTEM_DEVICE.VAHA;

  const [isAnimationVisible, setIsAnimationVisible] = useState(false);

  useEffect(() => {
    setIsAnimationVisible(false);
    clearTimeout(timeoutRef.current);
    timeoutRef.current = undefined;

    const currentPathWithoutLanguage = pathname.replace(/^\/(\w{2})?\/?/, '');
    const slashIndex = currentPathWithoutLanguage.indexOf('/');
    const currentMainPath =
      slashIndex > 0
        ? currentPathWithoutLanguage.slice(0, slashIndex)
        : currentPathWithoutLanguage;

    if (PagesWithAnimatedScrollEnabled.includes(currentMainPath)) {
      setIsAnimationVisible(true);

      timeoutRef.current = setTimeout(() => {
        setIsAnimationVisible(false);
      }, SecondsToKeepAnimationVisible * 1000);
    }

    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    };
  }, [pathname]);

  if (!isVaha || !isAnimationVisible) {
    return null;
  }
  return <AnimatedScroll />;
};

export default AnimatedScrollWrapper;
