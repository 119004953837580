import { useState } from 'react';
import { SOUND_WORLD_PATH } from '../pages/SoundWorld/SoundWorld';

export function useHomePath() {
  const [systemDeviceState] = useState(detectHomePath());

  return systemDeviceState;
}

const detectHomePath = () => {
  if (window.location.pathname.endsWith(SOUND_WORLD_PATH)) {
    return SOUND_WORLD_PATH;
  }
  return '';
};
