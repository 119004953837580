import ConfigurableLink from '../../components/ConfigurableLink/ConfigurableLink';

interface Args {
  scope: string;
  className?: string;
}

const PrivacyPolicyDeutsch = ({ scope, className = '' }: Args) => {
  return (
    <>
      <h1 className={`${scope}-heading ${className}`}>Datenschutzerklärung</h1>
      <h2>Porsche Charging Lounge Website</h2>
      <br />
      <br />

      <div className={`${scope}-textContainer`}>
        {/* eslint-disable react/no-unescaped-entities */}
        <div>
          Wir, die Dr. Ing. h.c. F. Porsche AG (nachfolgend „wir“ oder „Porsche
          AG“), freuen uns über Ihr Interesse an unserem Online-Angebot der
          Porsche Charging Lounge{' '}
          <ConfigurableLink
            href="https://porsche.charging-lounges.com/de"
            target="_blank"
            className={`${scope}-link`}
          >
            https://porsche.charging-lounges.com/
          </ConfigurableLink>{' '}
          (nachfolgend das „Online-Angebot“). Wir nehmen den Schutz Ihrer
          personenbezogenen Daten sehr ernst. Die Verarbeitung Ihrer
          personenbezogenen Daten erfolgt ausschließlich im Rahmen der
          gesetzlichen Bestimmungen des Datenschutzrechts, insbesondere der
          Datenschutzgrundverordnung (nachfolgend „DSGVO“). Mit dieser
          Datenschutzerklärung informieren wir Sie über die Verarbeitung Ihrer
          personenbezogenen Daten und über Ihre Rechte als betroffene Person im
          Rahmen des Online-Angebots. Informationen zur Verarbeitung
          personenbezogener Daten in anderen Bereichen entnehmen Sie bitte der
          jeweiligen spezifischen Datenschutzerklärung.
        </div>
        {/* eslint-enable react/no-unescaped-entities */}
        <br />

        <div>
          Sofern wir von externen Social-Media-Profilen auf diese
          Datenschutzerklärung verweisen, gelten die folgenden Erläuterungen nur
          insoweit, als die Verarbeitung in unserem Verantwortungsbereich
          erfolgt und, soweit keine spezielleren und deshalb vorrangigen
          Hinweise zum Datenschutz im Rahmen solcher Social-Media-Profile zur
          Verfügung gestellt werden.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          1. Verantwortlicher und Datenschutzbeauftragter
        </div>
        <br />

        <div>
          Verantwortlich für die Datenverarbeitung im Sinne der
          Datenschutzgesetze ist die:
        </div>
        <br />

        <div>Dr. Ing. h.c. F. Porsche AG</div>
        <div>Porscheplatz 1</div>
        <div>70435 Stuttgart</div>
        <div>Deutschland</div>
        <div>Tel: +49 (0) 711 911-0</div>
        <div>
          E-Mail:{' '}
          <ConfigurableLink
            href="mailto:info@porsche.de"
            className={`${scope}-link`}
          >
            info@porsche.de
          </ConfigurableLink>
        </div>
        <br />

        <div>
          Sollten Sie Fragen oder Anregungen zum Datenschutz haben, können Sie
          sich gerne an uns wenden. Unseren Datenschutzbeauftragten erreichen
          Sie wie folgt:
        </div>
        <br />

        <div>Dr. Ing. h.c. F. Porsche AG</div>
        <div>Beauftragter für Datenschutz</div>
        <div>Porscheplatz 1</div>
        <div>70435 Stuttgart</div>
        <div>Deutschland</div>
        <div>
          Kontakt:{' '}
          <ConfigurableLink
            href="https://www.porsche.com/germany/privacy/contact/"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/germany/privacy/contact/
          </ConfigurableLink>
        </div>
        <br />

        <div>
          In Bezug auf Verarbeitungen im Rahmen des Online-Angebots sind wir
          gemeinsam Verantwortliche mit der unten genannten Konzerngesellschaft
          der Porsche AG („wir“ steht im Folgenden auch für diese gemeinsam
          verantwortliche Konzerngesellschaft). Wir legen in Bezug auf die
          gemeinsamen Prozesse gemeinsam die Zwecke und die Mittel der
          Verarbeitung personenbezogener Daten fest. Wir haben in einer
          Vereinbarung über die gemeinsame Verantwortlichkeit nach Artikel 26
          DSGVO festgelegt, wie die jeweiligen Aufgaben und Zuständigkeiten bei
          der Verarbeitung personenbezogener Daten ausgestaltet sind und wer
          welche datenschutzrechtlichen Verpflichtungen erfüllt. Wir haben
          insbesondere festgelegt, wie ein angemessenes Sicherheitsniveau und
          Ihre Betroffenenrechte sichergestellt werden können, wie wir gemeinsam
          die datenschutzrechtlichen Informationspflichten erfüllen und wie wir
          potenzielle Datenschutzvorfälle überwachen können. Dazu gehört auch,
          dass wir die Erfüllung unserer Melde- und Benachrichtigungspflichten
          sicherstellen können. Soweit Sie sich an uns wenden, werden wir uns im
          Sinne der angesprochenen Vereinbarung nach Artikel 26 DSGVO abstimmen,
          um Ihre Anfrage zu beantworten und Ihre Betroffenenrechte zu
          gewährleisten.
        </div>
        <br />

        <div>Dr. Ing. h.c. F. Porsche AG</div>
        <div>Beauftragter für Datenschutz</div>
        <div>Porscheplatz 1</div>
        <div>70435 Stuttgart</div>
        <div>Deutschland</div>
        <div>
          Kontakt:{' '}
          <ConfigurableLink
            href="https://www.porsche.com/privacy-contact/"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/privacy-contact/
          </ConfigurableLink>
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          2. Gegenstand des Datenschutzes
        </div>
        <br />

        <div>
          Gegenstand des Datenschutzes ist der Schutz personenbezogener Daten.
          Diese sind alle Informationen, die sich auf eine identifizierte oder
          identifizierbare natürliche Person (sog. betroffene Person) beziehen.
          Hierunter fallen Angaben wie z.B. Name, Post-Adresse, E-Mail-Adresse
          oder Telefonnummer, aber auch sonstige Informationen, die im Rahmen
          der Nutzung des Online-Angebots entstehen, insbesondere Angaben über
          Beginn, Ende und Umfang der Nutzung sowie die Übermittlung Ihrer
          IP-Adresse.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          3. Zwecke und Rechtsgrundlagen der Datenverarbeitung
        </div>
        <br />

        <div>
          Nachfolgend erhalten Sie einen Überblick über Zwecke und
          Rechtsgrundlagen der Datenverarbeitung im Rahmen des Online-Angebots.
          Wir verarbeiten personenbezogene Daten jedenfalls entsprechend der
          rechtlichen Vorgaben, auch wenn im Einzelfall eine andere
          Rechtsgrundlage einschlägig sein sollte als im Folgenden angegeben.
        </div>
        <br />

        <div>
          Die Bereitstellung personenbezogener Daten durch Sie kann dabei
          gesetzlich oder vertraglich vorgeschrieben bzw. für einen
          Vertragsabschluss erforderlich sein. Wir weisen Sie gesondert darauf
          hin, wenn Sie verpflichtet sein sollten, personenbezogene Daten
          bereitzustellen und welche möglichen Folgen die Nichtbereitstellung
          dann hätte (z.B. ein Verlust von Ansprüchen oder unser Hinweis, ohne
          Bereitstellung bestimmter Angaben die angeforderte Leistung nicht zu
          erbringen). Die Nutzung des Online-Angebots ist grundsätzlich ohne
          Registrierung möglich. Die Nutzung einzelner Dienste und Funktionen
          kann eine vorherige Registrierung erfordern. Auch wenn Sie unser
          Online-Angebot ohne Registrierung nutzen, können dennoch
          personenbezogene Daten verarbeitet werden.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          3.1. Erfüllung vertraglicher und vorvertraglicher Pflichten
        </div>
        <br />

        <div>
          Wir verarbeiten Ihre personenbezogenen Daten, wenn dies für die
          Durchführung eines Vertrages, dessen Partei Sie sind, bzw. für die
          Durchführung vorvertraglicher Maßnahmen, die auf Ihre Anfrage hin
          erfolgen, erforderlich ist. Die Datenverarbeitung erfolgt auf Basis
          des Artikels 6 Absatz 1 Buchstabe b) DSGVO. Die Zwecke der
          Verarbeitung umfassen die Ermöglichung der Nutzung unserer
          spezifischen Produkte und Dienstleistungen im Rahmen des
          Online-Angebots.
        </div>

        <br />

        <div className={`${scope}-boldText`}>
          3.2. Erfüllung rechtlicher Verpflichtungen
        </div>
        <br />

        <div>
          Wir verarbeiten Ihre personenbezogenen Daten, um rechtliche
          Verpflichtungen einzuhalten, denen wir unterliegen. Die
          Datenverarbeitung erfolgt auf Basis des Artikels 6 Absatz 1 Buchstabe
          c) DSGVO. Die Verpflichtungen können sich z.B. aus dem Handels-,
          Steuer-, Geldwäsche-, Finanz- oder Strafrecht ergeben. Die Zwecke der
          Verarbeitung ergeben sich aus der jeweiligen rechtlichen
          Verpflichtung; die Verarbeitung dient in der Regel dem Zweck,
          staatlichen Kontroll- und Auskunftspflichten nachzukommen.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          3.3. Wahrung berechtigter Interessen
        </div>
        <br />

        <div>
          Wir verarbeiten Ihre personenbezogenen Daten darüber hinaus zur
          Wahrung berechtigter Interessen von uns oder Dritten, soweit nicht
          Ihre Interessen, die den Schutz Ihrer personenbezogenen Daten
          erfordern, überwiegen. Die Datenverarbeitung erfolgt auf Basis des
          Artikels 6 Absatz 1 Buchstabe f) DSGVO. Die Verarbeitung zur Wahrung
          berechtigter Interessen erfolgt zu folgenden Zwecken bzw. zur Wahrung
          folgender Interessen.
        </div>
        <br />
        <ul>
          <li>
            Bedarfsanalyse und Kundensegmentierung, z.B. Berechnung und
            Bewertung von Affinitäten, Präferenzen sowie Kundenpotenzialen;
          </li>
          <li>Bearbeitung von außervertraglichen Anfragen und Anliegen;</li>
          <li>
            Gewährleistung von Verfügbarkeit, Betrieb und Sicherheit technischer
            Systeme sowie technisches Datenmanagement;
          </li>
          <li>
            Beantwortung und Auswertung von Kontaktanfragen und Rückmeldungen.
          </li>
        </ul>
        <br />

        <div>3.3.1. Abruf des Online-Angebots</div>
        <br />

        <div>
          Wenn Sie das Online-Angebot aufrufen, werden Daten in Bezug auf Ihr
          Endgerät und Ihre Nutzung des Online-Angebots verarbeitet und in einer
          sog. Log-Datei gespeichert. Dies betrifft insbesondere technische
          Daten wie Datum und Uhrzeit des Zugriffs, Dauer des Besuchs, Art des
          Endgeräts, verwendetes Betriebssystem, genutzte Funktionen, Menge der
          gesendeten Daten, IP-Adresse und Referrer-URL. Diese Daten verarbeiten
          wir zur Sicherstellung des technischen Betriebs sowie zur Ermittlung
          und Beseitigung von Störungen. Wir verfolgen dabei das Interesse, die
          technische Funktionsfähigkeit dauerhaft sicherzustellen. Wir verwenden
          diese Daten nicht zu dem Zweck, Rückschlüsse auf Ihre Person zu
          ziehen.
        </div>
        <br />

        <div>3.3.2. Studien und Umfragen</div>
        <br />

        <div>
          Im Rahmen der Weiterentwicklung von Produkten, Diensten,
          Dienstleistungen und Betreuungsangeboten können Sie beim Besuch
          unseres Online-Angebots von Zeit zu Zeit dafür ausgewählt werden, an
          einer Studie oder Umfrage teilzunehmen. Die Teilnahme ist freiwillig.
          Wir verarbeiten Ihre personenbezogenen Daten, wenn dies für die
          Anbahnung, Durchführung und Auswertung der Studie oder Umfrage
          erforderlich ist. Dies beinhaltet auch die weitere Aufbereitung der
          Ergebnisse. Die Verarbeitung dient dazu, Erkenntnisse zur
          individuellen Kundenzufriedenheit bzw. zu allgemeinen Präferenzen und
          Vorstellungen (potentieller) Kunden zu generieren. Bitte beachten Sie
          insofern auch die Beschreibung der Studie oder Umfrage und die
          Begleitinformationen jenseits dieser Datenschutzerklärung. Wir
          verwenden die erhobenen Daten nicht zu dem Zweck, Rückschlüsse auf
          Ihre Person bzw. Ihre Identität zu ziehen. Direkt mit Ihrer Identität
          verbundene Daten werden nur dann verarbeitet, wenn Sie diese auf
          freiwilliger Basis im Rahmen der Studie oder Umfrage mitteilen. Sollte
          eine andere Gesellschaft als die Porsche AG für die Verarbeitung Ihrer
          Daten verantwortlich sein, wird dies entsprechend ausgewiesen. Wir
          setzen für die Durchführung der Studien und Umfragen ggf.
          weisungsgebundene Agenturen als Auftragsverarbeiter ein (vgl. dazu
          unter Ziffer 8).
        </div>
        <br />

        <div className={`${scope}-boldText`}>3.4. Einwilligung</div>
        <br />

        <div>
          Wir verarbeiten Ihre personenbezogenen Daten auf Grundlage
          entsprechender Einwilligung. Die Datenverarbeitung erfolgt auf Basis
          des Artikels 6 Absatz 1 Buchstabe a) DSGVO. Sollten Sie eine
          Einwilligung erteilen, ist diese stets zweckgebunden; die Zwecke der
          Verarbeitung ergeben sich jeweils aus dem Inhalt Ihrer
          Einwilligungserklärung. Eine erteilte Einwilligung können Sie
          jederzeit widerrufen, ohne dass die Rechtmäßigkeit, der aufgrund der
          Einwilligung bis zum Widerruf erfolgten Verarbeitung hiervon berührt
          wird.
        </div>
        <br />

        <div className={`${scope}-boldText`}>3.5. Zweckänderung</div>
        <br />

        <div>
          Soweit wir Ihre personenbezogenen Daten jenseits einer entsprechenden
          Einwilligung oder einer zwingenden Rechtsgrundlage zu einem anderen
          Zweck verarbeiten als zu demjenigen, zu dem die Daten erhoben wurden,
          berücksichtigen wir gemäß Artikel 6 Absatz 4 DSGVO die Vereinbarkeit
          des ursprünglichen und des nunmehr verfolgten Zwecks, die Art der
          personenbezogenen Daten, die möglichen Folgen der weiteren
          Verarbeitung für Sie und die Garantien zum Schutz der
          personenbezogenen Daten.
        </div>
        <br />

        <div className={`${scope}-boldText`}>3.6. Profiling</div>
        <br />

        <div>
          Wir führen keine automatisierte Entscheidungsfindung bzw. kein
          Profiling gemäß Artikel 22 DSGVO durch. Eine Profilbildung erfolgt nur
          zur Wahrung unserer berechtigten Interessen wie oben beschrieben.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          4. Zugriffsberechtigungen im Endgerät
        </div>
        <br />

        <div>
          Einige Funktionen unseres Online-Angebots erfordern die Erteilung der
          Berechtigung zum Zugriff auf Ihr Endgerät (z.B. Zugriff auf
          Standortdaten). Die Erteilung der Berechtigungen ist freiwillig.
          Sollten Sie jedoch die entsprechenden Funktionen nutzen wollen, ist
          die Erteilung der entsprechenden Berechtigungen erforderlich, da Sie
          andernfalls diese Funktionen nicht nutzen können. Die Berechtigungen
          bleiben so lange aktiv, wie Sie diese nicht in Ihrem Endgerät durch
          die Deaktivierung der jeweiligen Einstellung wieder zurückgesetzt
          haben.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          5. Cookies und vergleichbare Technologien
        </div>
        <br />

        <div>
          Wir verwenden im Rahmen des Online-Angebots Cookies und vergleichbare
          Technologien, die dazu dienen, mit Ihrem Endgerät zu kommunizieren und
          gespeicherte Informationen auszutauschen (nachfolgend insgesamt
          „Cookies“). Diese Cookies dienen in erster Linie dazu, die Funktionen
          des Online-Angebots nutzbar zu machen. Allgemeine Beispiele, in denen
          der Einsatz von Cookies in diesem Sinne technisch erforderlich ist,
          sind die Speicherung einer Sprachauswahl, von Login-Daten oder einer
          Einkaufs- bzw. Merkliste. Dementsprechend können technisch
          erforderliche Cookies von uns eingesetzt werden, um die unter Ziffer
          3.1 beschriebenen Verarbeitungen zu ermöglichen und den
          ordnungsgemäßen und sicheren Betrieb des Online-Angebots zu
          gewährleisten. Die Datenverarbeitung erfolgt dann auf Basis des
          Artikels 6 Absatz 1 Buchstabe b) und f) DSGVO, da sie erforderlich
          ist, um die von Ihnen gewählten Funktionen umzusetzen bzw. um unser
          berechtigtes Interesse an der Funktionsfähigkeit des Online-Angebots
          zu wahren.
        </div>
        <br />

        <div>
          Soweit wir darüber hinaus Cookies einsetzen sollten, um die Nutzung
          des Online-Angebots zu analysieren und zielgerichtet auf Ihre
          Interessen ausrichten zu können und ggf. auch, um Ihnen
          interessenbasierte Inhalte und Anzeigen bereitstellen zu können,
          erfolgt dies ausschließlich auf Basis Ihrer freiwilligen Einwilligung
          gemäß Artikel 6 Absatz 1 Buchstabe a) DSGVO. Sie haben dann die
          Möglichkeit, über das Einwilligungsmanagement im Rahmen des
          Online-Angebots Ihre entsprechenden Einstellungen vorzunehmen. Sie
          können eine hierüber erteilte Einwilligung jederzeit mit Wirkung für
          die Zukunft widerrufen. Weitere Informationen zu den Cookies und zu
          deren Funktion im Einzelnen sowie zu Einstellungs- und
          Widerrufsmöglichkeiten erhalten Sie direkt in den entsprechenden
          Bereichen des Einwilligungsmanagements. Bitte beachten Sie, dass wir
          das Einwilligungsmanagement im Rahmen des Online-Angebots
          grundsätzlich nur zur Verfügung stellen, wenn über die oben genannten
          technisch erforderlichen Cookies hinaus einwilligungsbasiert Cookies
          eingesetzt werden sollen.
        </div>
        <br />

        <div>
          Wenn Sie den Einsatz von Cookies insgesamt nicht wünschen, können Sie
          auch deren Speicherung ggf. durch entsprechende Einstellungen Ihres
          Endgeräts verhindern. Gespeicherte Cookies können Sie in den
          Systemeinstellungen Ihres Endgeräts jederzeit löschen. Bitte beachten
          Sie, dass die Blockierung bestimmter Arten von Cookies zu einer
          beeinträchtigten Nutzung unseres Online-Angebots führen kann.
        </div>
        <br />

        <div>
          Ferner setzen wir weitere Technologien ein, die ähnlich wie Cookies
          dazu dienen, die sichere und anwenderfreundliche Nutzbarkeit der
          Webseite zu gewährleisten (z.B. durch Schutz vor Missbrauch oder die
          Auswertung der Nutzung). Technisch unterscheiden sich diese weiteren
          Technologien von Cookies, da durch sie keine Informationen auf Ihrem
          Endgerät gespeichert werden bzw. nicht auf dort bereits gespeicherte
          Informationen zugegriffen wird. Soweit durch diese weiteren
          Technologien Daten verarbeitet werden, die dem Datenschutzrecht
          unterfallen (z.B. IP-Adressen) verarbeiten wir diese Daten auf Basis
          des Artikels 6 Absatz 1 Buchstaben b) und f) DSGVO zur Bereitstellung
          der Webseite, zur Sicherstellung des technischen Betriebs sowie zum
          Zwecke der Ermittlung und Beseitigung von Störungen. Wir verfolgen
          dabei zudem das Interesse, die technische Funktionsfähigkeit der
          Webseite dauerhaft sicherzustellen, die Leistungsfähigkeit zu
          verbessern und die Benutzererfahrung zu optimieren. Bei Aufruf unserer
          Webseite werden diese Daten automatisch verarbeitet. Ohne die
          Bereitstellung der Daten können Sie unsere Webseite nicht nutzen. Wir
          verwenden diese Daten nicht zu dem Zweck, Rückschlüsse auf Ihre Person
          bzw. Ihre Identität zu ziehen.
        </div>
        <br />

        <div className={`${scope}-boldText`}>6. Eingebundene Drittdienste</div>
        <br />

        <div>
          Soweit wir im Rahmen unseres Online-Angebots Dienste anderer Anbieter
          einbinden, um Ihnen bestimmte Inhalte oder Funktionen zu bieten (z.B.
          das Abspielen von Videos oder eine Routenplanung) und wir dabei
          personenbezogene Daten verarbeiten, erfolgt dies auf Basis des
          Artikels 6 Absatz 1 Buchstabe b) und f) DSGVO. Denn die
          Datenverarbeitung ist dann erforderlich, um die von Ihnen gewählten
          Funktionen umzusetzen bzw. um unser berechtigtes Interesse an einem
          optimalen Funktionsumfang des Online-Angebots zu wahren. Soweit im
          Rahmen dieser Drittdienste möglicherweise Cookies eingesetzt werden,
          gelten die Ausführungen unter Ziffer 5. Bitte informieren Sie sich in
          Bezug auf die Drittdienste auch über die Datenschutzerklärung des
          jeweiligen Anbieters.
        </div>
        <br />

        <div>
          Dienste anderer Anbieter, die wir einbinden bzw. auf die von uns
          verwiesen wird, werden von den entsprechenden Dritten bereitgestellt.
          Unter die Drittdienste fallen grundsätzlich auch Angebote von anderen
          Konzerngesellschaften der Porsche AG und von Porsche-Zentren. Wir
          haben grundsätzlich keinen Einfluss auf Inhalt und Funktion der
          Drittdienste und sind für die Verarbeitung Ihrer personenbezogenen
          Daten durch deren Anbieter grundsätzlich nicht verantwortlich, soweit
          die Drittdienste nicht vollständig in unserem Auftrag konzipiert und
          dann von uns in eigener Verantwortlichkeit eingebunden werden. Soweit
          die Einbindung eines Drittdienstes dazu führt, dass wir mit dessen
          Anbieter gemeinsame Prozesse etablieren, legen wir mit diesem Anbieter
          in einer Vereinbarung über die gemeinsame Verantwortlichkeit nach
          Artikel 26 DSGVO fest, wie die jeweiligen Aufgaben und Zuständigkeiten
          bei der Verarbeitung personenbezogener Daten ausgestaltet sind und wer
          welche datenschutzrechtlichen Verpflichtungen erfüllt. Soweit Cookies
          auch auf Basis Ihrer Einwilligung gesetzt werden sollen, erhalten Sie
          weitere Informationen zur Verantwortlichkeit für das Setzen dieser
          Cookies bzw. damit ggf. verbundene Drittdienste auch in den
          entsprechenden Bereichen des Einwilligungsmanagements.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          7. Empfänger personenbezogener Daten
        </div>
        <br />

        <div>
          Innerhalb unseres Unternehmens haben nur diejenigen Personen Zugriff
          auf Ihre personenbezogenen Daten, die diese für die jeweils genannten
          Zwecke benötigen. An externe Empfänger geben wir Ihre
          personenbezogenen Daten nur weiter, wenn hierfür eine gesetzliche
          Erlaubnis besteht oder uns Ihre Einwilligung vorliegt. Nachfolgend
          finden Sie eine Übersicht über die entsprechenden Empfänger:
        </div>

        <ul>
          <li>
            Auftragsverarbeiter: Konzerngesellschaften oder externe
            Dienstleister, beispielsweise in den Bereichen der technischen
            Infrastruktur und Wartung, die sorgfältig ausgewählt und überprüft
            werden. Die Auftragsverarbeiter dürfen die Daten ausschließlich nach
            unseren Weisungen verwenden.
          </li>
          <li>
            Öffentliche Stellen: Behörden und staatliche Institutionen, wie z.B.
            Steuerbehörden, Staatsanwaltschaften oder Gerichte, an die wir z.B.
            zur Erfüllung rechtlicher Verpflichtungen oder zur Wahrung
            berechtigter Interessen personenbezogene Daten übermitteln (müssen).
          </li>
          <li>
            Private Stellen: Konzerngesellschaften, Porsche
            Vertriebsgesellschaften (inkl. Gesellschaften, die Dienste im
            Bereich Porsche Connect und Smart Mobility anbieten), Händler- und
            Service-Betriebe, Kooperationspartner, (nicht weisungsgebundene)
            Dienstleister oder beauftragte Personen wie z.B. Porsche Zentren und
            Porsche Service Zentren, Finanzierungsbanken, Auskunfteien oder
            Transportdienstleister.
          </li>
        </ul>

        <div className={`${scope}-boldText`}>
          8. Kunden- und Interessentenbetreuung
        </div>
        <br />

        <div>
          Nachfolgend möchten wir Ihnen weitere datenschutzrechtliche
          Informationen zur Umsetzung der Kunden- und Interessentenbetreuung bei
          Porsche geben. Die Maßnahmen dienen dazu, die kunden- und
          interessentengerechte Betreuung sicherzustellen.
        </div>
        <br />

        <div className={`${scope}-boldText ${scope}-textWithLeftPadding`}>
          8.1. Gemeinsame Kunden- und Interessentenbetreuung bei Porsche
        </div>
        <br />

        <div>
          Die unter Ziffer 3 genannten Maßnahmen im Rahmen der Kunden- und
          Interessentenbetreuung (insbesondere Service und Support, Umsetzung
          rechtlicher Vorgaben, Bedarfsanalysen, individuelle Betreuung über die
          gewünschten Kommunikationskanäle) werden grundsätzlich nicht vom
          Verantwortlichen allein durchgeführt. An der Kunden- und
          Interessentenbetreuung unter der Marke Porsche sind neben dem
          zuständigen Porsche Zentrum auch die Porsche Deutschland GmbH als
          Importeur, die Dr. Ing. h.c. F. Porsche AG als Hersteller und die mit
          ihr verbundenen Unternehmen in den Bereichen Finanz- und
          Mobilitätsdienstleistungen, digitale Dienstleistungen und
          Lifestyleprodukte beteiligt. Eine aktuelle Aufstellung der beteiligten
          Gesellschaften mit deren Kontaktdaten ist hier abrufbar:{' '}
          <ConfigurableLink
            href="https://www.porsche.com/germany/joint-customer-care"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/germany/joint-customer-care
          </ConfigurableLink>
        </div>
        <br />

        <div>
          Durch die Nutzung einer zentralen Plattform vermeiden wir, dass
          Informationen zu Ihren Produkten, Kontaktdaten und Interessen bei
          Ihrem Ansprechpartner von Porsche nicht verfügbar sind und Sie deshalb
          ggf. erst an eine andere beteiligte Gesellschaft verwiesen werden
          müssen. Dies gilt auch für den Fall, wenn die Betreibergesellschaft
          Ihres jeweiligen Porsche Zentrums wechselt. Durch den Datenaustausch
          und -abgleich stellen wir sicher, dass Sie optimal betreut und beraten
          werden können. Dabei haben selbstverständlich nur die beteiligten
          Gesellschaften Zugriff auf Ihre Daten, die diese auch operativ
          benötigen.
        </div>
        <br />

        <div>
          Die gemeinsame Kunden- und Interessentenbetreuung kann in bestimmten
          Fällen zu einer gemeinsamen Verantwortlichkeit führen. Aus diesem
          Grund haben die beteiligten Gesellschaften in einer Vereinbarung nach
          Artikel 26 DSGVO festgelegt, wie die jeweiligen Aufgaben und
          Zuständigkeiten bei der Verarbeitung personenbezogener Daten
          ausgestaltet sind und wer welche datenschutzrechtlichen
          Verpflichtungen erfüllt. Es wurde insbesondere festgelegt, wie ein
          angemessenes Sicherheitsniveau erreicht werden kann und wie Ihre
          Betroffenenrechte und die datenschutzrechtlichen Informationspflichten
          gewährleistet werden können. Die Porsche Deutschland GmbH (Porschestr.
          1, 74321 Bietigheim-Bissingen,{' '}
          <ConfigurableLink
            href="https://www.porsche.com/germany/privacy/contact/"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/germany/privacy/contact/
          </ConfigurableLink>
          ) steht Ihnen neben den anderen beteiligten Gesellschaften als
          zentraler Ansprechpartner zur Verfügung.
        </div>
        <br />

        <div className={`${scope}-boldText ${scope}-textWithLeftPadding`}>
          8.2. Individuelle Kunden- und Interessentenbetreuung
        </div>
        <br />

        <div>
          Soweit Sie Ihre freiwillige Einwilligung in die individuelle Kunden-
          und Interessentenbetreuung erteilt haben, werden Kontaktdaten,
          Betreuungs- und Vertragsdaten (z.B. zu Kauf, Leasing oder
          Finanzierung), Serviceinformationen sowie Daten zu Interessen,
          Fahrzeugen und verwendeten Diensten und Produkten von den an der
          gemeinsamen Kunden- und Interessentenbetreuung beteiligten
          Gesellschaften verwendet, um Ihnen persönlich zugeschnittene
          Informationen und Angebote zu Fahrzeugen, Dienstleistungen und
          weiteren Produkten von Porsche, Einladungen zu Veranstaltungen sowie
          Befragungen zu Zufriedenheit und Erwartungen über die gewünschten
          Kommunikationskanäle zu senden und ein individuelles Kundenprofil
          anzulegen.
        </div>
        <br />

        <div>
          Welche Daten hierzu konkret verwendet werden, hängt davon ab, welche
          Daten auf Basis von Aufträgen, Bestellungen und Beratungen erhoben
          oder von Ihnen zur Verfügung gestellt wurden (z.B. im
          Beratungsgespräch im Porsche Zentrum oder im Rahmen Ihrer Aktivitäten
          unter Ihrer Porsche ID bei My Porsche). Die Daten können auch aus
          Aufträgen oder Bestellungen stammen, die in Zusammenarbeit mit
          Kooperationspartnern (z.B. Versicherungen) abgewickelt werden und von
          denen wir dann ggf. die Informationen erhalten. Soweit entsprechende
          Freigaben erteilt wurden, werden ggf. auch weitere Datenquellen
          einbezogen. Dies können Daten aus dem Fahrzeug sein (z.B. zu Ihrem
          Fahrverhalten) oder zur Nutzung digitaler Medien (z.B. zur
          Websitenutzung). Nähere Informationen zur Zusammenführung der Daten
          erhalten Sie bei der entsprechenden Freigabe.
        </div>
        <br />

        <div>
          Um ein begeisterndes Marken- und Betreuungserlebnis mit Porsche zu
          bieten und die Kommunikation und Interaktion so persönlich und
          relevant wie möglich zu gestalten, werden die genannten Daten für
          Bedarfsanalysen und Kundensegmentierungen verwendet. Auf dieser
          Grundlage können im Rahmen der individuellen Kunden- und
          Interessentenbetreuung von den beteiligten Gesellschaften z.B.
          Affinitäten, Präferenzen und Potenziale ermittelt werden. Beispiele
          für solche Maßnahmen, um die Betreuung zu individualisieren, sind
          Kennzahlen zu Ihren wahrscheinlichen Produktinteressen und zu Ihrer
          Zufriedenheit. Die entsprechenden Informationen und Analyseergebnisse
          werden in Ihrem Kundenprofil hinterlegt und stehen dann für die
          Ausgestaltung der Kunden- und Interessentenbetreuung zur Verfügung.
          Die persönliche Auswertung und Zuordnung in einem Kundenprofil erfolgt
          dabei nur, wenn Sie Ihre freiwillige Einwilligung in die individuelle
          Kunden- und Interessentenbetreuung erteilt haben. Eine individuelle
          Kunden- und Interessentenbetreuung ohne diese Maßnahmen zur
          Optimierung und Personalisierung wird nicht angeboten.
        </div>
        <br />

        <div>
          Ohne Ihre Einwilligung verwenden wir die genannten Daten im Rahmen der
          Kunden- und Interessentenbetreuung nur dazu, auf Basis der
          aggregierten Daten von Kunden und Interessenten allgemeine
          Auswertungen durchzuführen, um unsere Angebote und Systeme zu
          optimieren und an übergreifenden Interessen auszurichten. Bitte
          beachten Sie, dass Auswertungen Ihrer Daten ggf. auch jenseits der
          Kunden- und Interessentenbetreuung erfolgen, dies erfolgt dann auf
          Grundlage Ihrer spezifischen Einwilligung oder einer anderen
          Rechtsgrundlage.
        </div>
        <br />

        <div>
          Wenn wir E-Mails zur individuellen Kunden- und Interessentenbetreuung
          versenden, setzen wir ggf. marktübliche Technologien wie Zählpixel
          oder Click-Through-Links ein. Hiermit können wir analysieren, welche
          bzw. wie viele E-Mails zugestellt und/oder zurückgewiesen und/oder
          geöffnet werden. Letzteres erfolgt insbesondere per Zählpixel. Eine
          Messung der Öffnungsrate unserer E-Mails mittels Zählpixel ist nicht
          vollumfänglich möglich, wenn Sie in Ihrem E-Mail-Programm die Anzeige
          von Bildern deaktiviert haben. In diesem Fall wird Ihnen die E-Mail
          nicht vollständig angezeigt. Es ist uns jedoch weiterhin möglich
          nachzuvollziehen, ob eine E-Mail geöffnet wurde, wenn Sie in der
          E-Mail auf Text- oder Grafik-Links klicken. Durch den Einsatz von
          Click-Through-Links können wir analysieren, welche Links in unseren
          E-Mails angeklickt werden und ableiten welches Interesse an bestimmten
          Themen besteht. Dabei werden Sie beim Klick auf den entsprechenden
          Link vor dem Aufruf der Zielseite über unseren separaten Analyseserver
          geführt. Anhand der Analyseergebnisse können wir E-Mails im Rahmen der
          individuellen Kunden- und Interessentenbetreuung relevanter gestalten,
          zielgerichteter versenden oder den Versand von E-Mails unterbinden.
          Die Versendung von E-Mails und die Auswertung der Nutzung erfolgen
          nur, wenn Sie Ihre freiwillige Einwilligung in die individuelle
          Kunden- und Interessentenbetreuung erteilt haben. Eine individuelle
          Kunden- und Interessentenbetreuung ohne die beschriebene Auswertung
          zur Optimierung wird nicht angeboten.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          9. Datenverarbeitung in Drittländern
        </div>
        <br />

        <div>
          Findet eine Datenübermittlung an Stellen statt, deren Sitz oder deren
          Ort der Datenverarbeitung nicht in einem Mitgliedstaat der
          Europäischen Union, einem anderen Vertragsstaat des Abkommens über den
          Europäischen Wirtschaftsraum oder einem Staat, für den durch einen
          Beschluss der Europäischen Kommission ein angemessenes
          Datenschutzniveau festgestellt wurde, gelegen ist, stellen wir vor der
          Weitergabe sicher, dass die Datenübermittlung entweder durch einen
          gesetzlichen Erlaubnistatbestand gedeckt ist, Garantien für ein
          angemessenes Datenschutzniveau in Bezug auf die Datenübermittlung
          bestehen (z.B. durch die Vereinbarung vertraglicher Gewährleistungen,
          behördlich anerkannte Regelungen oder verbindliche interne
          Datenschutzvorschriften beim Empfänger) oder Sie Ihre Einwilligung in
          die Datenübermittlung erteilt haben.
        </div>
        <br />

        <div>
          Soweit die Datenübermittlung auf Grundlage von Artikel 46, 47 oder 49
          Absatz 1 Unterabsatz 2 DSGVO erfolgt, können Sie bei uns eine Kopie
          der Garantien für das Bestehen eines angemessenen Datenschutzniveaus
          in Bezug auf die Datenübermittlung oder einen Hinweis auf die
          Verfügbarkeit einer Kopie der Garantien erhalten. Bitte nutzen Sie
          hierfür die Angaben unter Ziffer 1.
        </div>
        <br />

        <div className={`${scope}-boldText`}>10. Speicherdauer, Löschung</div>
        <br />

        <div>
          Wir speichern Ihre personenbezogenen Daten, wenn hierfür eine
          gesetzliche Erlaubnis besteht, nur so lange wie zur Erreichung der
          verfolgten Zwecke erforderlich bzw. solange Sie die Einwilligung nicht
          widerrufen haben. Im Falle eines Widerspruchs gegen die Verarbeitung
          löschen wir Ihre personenbezogenen Daten, es sei denn, die
          Weiterverarbeitung ist nach den gesetzlichen Bestimmungen weiterhin
          erlaubt. Wir löschen Ihre personenbezogenen Daten auch dann, wenn wir
          hierzu aus anderen rechtlichen Gründen verpflichtet sind. Unter
          Anwendung dieser allgemeinen Grundsätze löschen wir Ihre
          personenbezogenen Daten in der Regel unverzüglich.
        </div>

        <ul>
          <li>
            nach Wegfall der rechtlichen Grundlage und sofern keine anderweitige
            Rechtsgrundlage (z.B. handels- und steuerrechtliche
            Aufbewahrungsfristen) eingreift. Falls Letzteres zutrifft, löschen
            wir die Daten nach Wegfall der anderweitigen Rechtsgrundlage;
          </li>
          <li>
            wenn Ihre personenbezogenen Daten für die von uns verfolgten Zwecke
            nicht mehr erforderlich sind und keine anderweitige Rechtsgrundlage
            (z.B. handels- und steuerrechtliche Aufbewahrungsfristen) eingreift.
            Falls Letzteres zutrifft, löschen wir die Daten nach Wegfall der
            anderweitigen Rechtsgrundlage.
          </li>
        </ul>
        <br />

        <div className={`${scope}-boldText`}>11. Betroffenenrechte</div>
        <br />

        <div>
          <span className={`${scope}-boldText`}>Auskunftsrecht</span>: Sie haben
          das Recht, Auskunft über die von uns zu Ihrer Person gespeicherten
          Daten zu erhalten.
        </div>
        <br />

        <div>
          <span className={`${scope}-boldText`}>
            Berichtigungs- und Löschungsrecht
          </span>
          : Sie können von uns die Berichtigung falscher Daten und - soweit die
          gesetzlichen Voraussetzungen erfüllt sind - Löschung Ihrer Daten
          verlangen.
        </div>
        <br />

        <div>
          <span className={`${scope}-boldText`}>
            Einschränkung der Verarbeitung
          </span>
          : Sie können von uns - soweit die gesetzlichen Voraussetzungen erfüllt
          sind - verlangen, dass wir die Verarbeitung Ihrer Daten einschränken.
        </div>
        <br />

        <div>
          <span className={`${scope}-boldText`}>Datenübertragbarkeit</span>:
          Sollten Sie uns Daten auf Basis eines Vertrages oder einer
          Einwilligung bereitgestellt haben, so können Sie bei Vorliegen der
          gesetzlichen Voraussetzungen verlangen, dass Sie die von Ihnen
          bereitgestellten Daten in einem strukturierten, gängigen und
          maschinenlesbaren Format erhalten oder dass wir diese an einen anderen
          Verantwortlichen übermitteln.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          Widerspruch: Sie haben das Recht, aus Gründen, die sich aus ihrer
          besonderen Situation ergeben, jederzeit der Datenverarbeitung durch
          uns zu widersprechen, soweit diese auf der Wahrung berechtigter
          Interessen beruht. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch
          machen, werden wir die Verarbeitung einstellen, es sei denn, wir
          können zwingende schutzwürdige Gründe für die Weiterverarbeitung
          nachweisen, welche Ihre Rechte und Interessen überwiegen.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          Widerspruch gegen Direktwerbung: Sofern wir Ihre personenbezogenen
          Daten verarbeiten, um Direktwerbung zu betreiben, haben Sie das Recht,
          jederzeit der Datenverarbeitung durch uns zu diesem Zweck zu
          widersprechen. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen,
          werden wir die Verarbeitung zu diesem Zweck einstellen.
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          Widerruf der Einwilligung: Sofern Sie uns eine Einwilligung in die
          Verarbeitung Ihrer personenbezogenen Daten erteilt haben, können Sie
          diese jederzeit mit Wirkung für die Zukunft widerrufen. Die
          Rechtmäßigkeit der Verarbeitung Ihrer Daten bis zum Widerruf bleibt
          hiervon unberührt.
        </div>
        <br />

        <div>
          <span className={`${scope}-boldText`}>
            Beschwerderecht bei der Aufsichtsbehörde
          </span>
          : Sie können zudem eine Beschwerde bei der zuständigen
          Aufsichtsbehörde einreichen, wenn Sie der Meinung sind, dass die
          Verarbeitung Ihrer Daten gegen geltendes Recht verstößt. Sie können
          sich hierzu an die Aufsichtsbehörde wenden, die für Ihren Wohnort bzw.
          Ihr Land zuständig ist oder an die für uns zuständige
          Aufsichtsbehörde.
        </div>
        <br />

        <div>
          <span className={`${scope}-boldText`}>
            Ihr Kontakt zu uns und die Ausübung Ihrer Rechte
          </span>
          : Des Weiteren können Sie sich bei Fragen zur Verarbeitung Ihrer
          personenbezogenen Daten und zu Ihren Betroffenenrechten unentgeltlich
          mit uns in Verbindung setzen. Wenden Sie sich bitte an{' '}
          <ConfigurableLink
            href="https://www.porsche.com/privacy-contact/"
            target="_blank"
            className={`${scope}-link`}
          >
            https://www.porsche.com/privacy-contact/
          </ConfigurableLink>{' '}
          oder postalisch an die oben unter Ziffer 1 angegebene Anschrift. Bitte
          stellen Sie dabei sicher, dass uns eine eindeutige Identifizierung
          Ihrer Person möglich ist. Beim Widerruf der Einwilligung können Sie
          alternativ auch den Kontaktweg wählen, den Sie bei der Abgabe der
          Einwilligung verwendet haben.
        </div>
        <br />

        <div className={`${scope}-boldText`}>12. Stand</div>
        <br />

        <div>
          Es gilt die aktuellste Version dieser Datenschutzerklärung. Version 4
          – Stand 26.07.2023
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicyDeutsch;
