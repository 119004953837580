import ConfigurableLink from '../../components/ConfigurableLink/ConfigurableLink';

interface Args {
  scope: string;
}

const ImprintEnglish = ({ scope }: Args) => {
  return (
    <div>
      <h1 className={`${scope}-heading`}>Provider</h1>
      <div className={`${scope}-textContainer`}>
        <div className={`${scope}-boldText`}>Dr. Ing. h.c. F. Porsche AG</div>
        <div>Porscheplatz 1</div>
        <div>D-70435 Stuttgart</div>
        <br />
        <div>
          E-Mail:{' '}
          <ConfigurableLink
            className={`${scope}-link`}
            href="mailto:info@porsche.de"
          >
            info@porsche.de
          </ConfigurableLink>
        </div>
        <div>Tel: (+49) 0711 911-0</div>
        <br />

        <div>represented by the Executive Board:</div>
        <div>Oliver Blume, Chairman</div>
        <div>Lutz Meschke, Deputy Chairman</div>
        <div>Andreas Haffner</div>
        <div>Detlev von Platen</div>
        <div>Albrecht Reimold</div>
        <div>Barbara Frenkel</div>
        <div>Michael Steiner</div>
        <br />

        <div>Company Register: Amtsgericht Stuttgart</div>
        <div>Register No. 730623</div>
        <div>VAT No.: DE 147 799 625</div>
        <br />

        <div className={`${scope}-boldText`}>
          Consumer information pursuant to EU Regulation No. 524/2013
        </div>
        <br />

        <div>
          The European Commission provides a platform for online dispute
          resolution. You will find this platform at{' '}
          <ConfigurableLink
            href="http://ec.europa.eu/consumers/odr/"
            target="_blank"
            className={`${scope}-link`}
          >
            http://ec.europa.eu/consumers/odr/
          </ConfigurableLink>
        </div>
        <br />

        <div>
          Our e-mail address is:{' '}
          <ConfigurableLink
            href="mailto:info@porsche.de"
            className={`${scope}-link`}
          >
            info@porsche.de
          </ConfigurableLink>
        </div>
        <br />

        <div className={`${scope}-boldText`}>
          Consumer information pursuant to the Act on Alternative Dispute
          Resolution in Consumer Matters
        </div>
        <br />

        <div>
          Dr. Ing. h.c. F. Porsche AG is not willing or obligated to participate
          in dispute resolution processes before a consumer dispute resolution
          body.
        </div>
        <br />

        <div className={`${scope}-boldText`}>Legal notice</div>
        <br />

        <div>
          Texts, images, graphics, animations, video and audio files and all
          other content on this website are subject to the legal provisions of
          copyright law and, where applicable, further intellectual property
          rights. The protected content of this website may not be duplicated,
          shared, reproduced, made publicly available or used in any other way
          without the permission of the holder of the rights.
        </div>
        <br />

        <div>
          We reserve the right to alter design, fit-out and scope of delivery,
          and to vary colour tones. Errors and omissions excepted. All prices
          shown are subject to change.
        </div>
      </div>
    </div>
  );
};

export default ImprintEnglish;
