import { useTranslation } from 'react-i18next';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import './Paragraph.scss';

interface Args {
  translateKey: string;
  className?: string;
}

export default function Paragraph({ translateKey, className = '' }: Args) {
  const scope = 'Paragraph';
  const systemDevice = useSystemDevice();
  const { t } = useTranslation();

  return (
    <p
      className={`${className} ${scope} ${scope}--${systemDevice}`}
      dangerouslySetInnerHTML={{
        __html: t(translateKey),
      }}
    ></p>
  );
}
