import { useTranslation } from 'react-i18next';
import Paragraph from '../../../components/Paragraph/Paragraph';
import { useGetImagesFolder } from '../../../hooks/useGetImagesFolder';
import { IndividualReadAndRechargeComponentPropsType } from './View';

export default function ReadAndRechargePage1({
  pageData,
  scope,
}: IndividualReadAndRechargeComponentPropsType) {
  const { t } = useTranslation();
  const imagesFolder = useGetImagesFolder();

  return (
    <>
      <div className={`${scope}-caption`}>{t(pageData.category)}</div>
      <div className={`${scope}-title`}>{t(pageData.title)}</div>
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_1_paragraph_1"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_1_paragraph_2"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_1_paragraph_3"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_1_paragraph_4"
      />
      <Paragraph
        className={`${scope}-paragraph`}
        translateKey="read_and_recharge_page_1_paragraph_5"
      />
      <div className={`${scope}-tile`}>
        <img
          alt={`${t(pageData.title)}`}
          className={`${scope}-tileImage`}
          src={`${imagesFolder}/readAndRecharge_article1_tile.jpg`}
        />
      </div>
    </>
  );
}
