import { useTranslation } from 'react-i18next';
import { LANGUAGES } from '../../constants/language.const';
import { changeUsercentricsLanguage } from '../../helpers/changeUsercentricsLanguage';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useGetCurrentPathWithoutLanguage } from '../../hooks/useGetCurrentPathWithoutLanguage';
import { useHomePath } from '../../hooks/useHomePath';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import './Header.scss';
import SvgIcon from '../SvgIcon/SvgIcon';

export default function Header() {
  const scope = 'Header';
  const customNavigate = useCustomNavigate();
  const { i18n } = useTranslation();
  const systemDevice = useSystemDevice();
  const getCurrentPath = useGetCurrentPathWithoutLanguage();
  const homePath = useHomePath();
  const isHome = getCurrentPath() === homePath;
  const availableLanguage =
    i18n.language === LANGUAGES[0] ? LANGUAGES[1] : LANGUAGES[0];

  const goBack = () => {
    const page = `/${getCurrentPath()}`.replace(/\/[\w-=_]*$/, homePath);
    customNavigate({ page });
  };

  const changeLanguage = async () => {
    const language = availableLanguage;
    await i18n.changeLanguage(language);
    changeUsercentricsLanguage(language);
    return customNavigate({ language, page: homePath });
  };

  return (
    <header className={`${scope} ${scope}--${systemDevice}`}>
      {!isHome && (
        <button
          className={scope + '-backButton'}
          type="button"
          onClick={() => goBack()}
        >
          <SvgIcon className={scope + '-back'} name={`back`} />
        </button>
      )}

      <button
        className={scope + '-logoButton'}
        type="button"
        onClick={() => customNavigate({ page: homePath })}
      >
        <SvgIcon className={scope + '-logo'} name={`porsche`} />
      </button>

      {isHome && (
        <>
          <button
            type="button"
            onClick={() => changeLanguage()}
            className={scope + '-languages'}
          >
            {availableLanguage}
          </button>
        </>
      )}

      {!isHome && (
        <button
          className={scope + '-homeButton'}
          type="button"
          onClick={() => customNavigate({ page: homePath })}
        >
          <SvgIcon className={scope + '-home'} name={`home`} />
        </button>
      )}
    </header>
  );
}
