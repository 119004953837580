import { useTranslation } from 'react-i18next';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import Image from '../Image/Image';
import { WaterDropColumnDetails } from './WaterDropSection';
import './PairedImages.scss';

interface Args {
  data: WaterDropColumnDetails;
  className?: string;
}

const PairedImages = ({ data, className = '' }: Args) => {
  const scope = 'PairedImages';
  const systemDevice = useSystemDevice();
  const { t } = useTranslation();

  return (
    <div className={`${scope} ${scope}--${systemDevice} ${className}`}>
      {data.items.map((singleItem) => {
        return (
          <Image
            key={singleItem.title}
            name={singleItem.image}
            imageCustomPath={`${process.env.PUBLIC_URL}/media/images`}
            alt={t(singleItem.title)}
            className={`${scope}-image`}
          />
        );
      })}
    </div>
  );
};

export default PairedImages;
