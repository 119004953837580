import './SoundWorld.scss';
import { useRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import H5AudioPlayer from 'react-h5-audio-player';
import { useTranslation } from 'react-i18next';
import Paragraph from '../../components/Paragraph/Paragraph';
import { LANGUAGES } from '../../constants/language.const';
import { useGetImagesFolder } from '../../hooks/useGetImagesFolder';
import { useSystemDevice } from '../../hooks/useSystemDevice';

export const SOUND_WORLD_PATH = 'sound-world';

const SOUND_RECORDS = [
  {
    title: 'sound_world_record_1',
    image: 'soundWorld_recordCover_1',
    audioDe: '/media/audios/DieMelodieDesWindes_TheMelodyOfTheWind.mp3',
  },
  {
    title: 'sound_world_record_2',
    image: 'soundWorld_recordCover_2',
    audioDe:
      '/media/audios/UnsereNatuerlichenEnergiequellen_OurNaturalEnergySources.mp3',
  },
  {
    title: 'sound_world_record_3',
    image: 'soundWorld_recordCover_3',
    audioDe: '/media/audios/Schulspass_FunAtSchool.mp3',
  },
  {
    title: 'sound_world_record_4',
    image: 'soundWorld_recordCover_4',
    audioDe: '/media/audios/StromImEinsatz.mp3',
    audioEn: '/media/audios/ElectricityInUse.mp3',
  },
  {
    title: 'sound_world_record_5',
    image: 'soundWorld_recordCover_5',
    audioDe: '/media/audios/TierfreundeAusAllerWelt.mp3',
    audioEn: '/media/audios/AnimalFriendsFromAllOverTheWorld.mp3',
  },
  {
    title: 'sound_world_record_6',
    image: 'soundWorld_recordCover_6',
    audioDe: '/media/audios/UnterwegsAufDerWelt.mp3',
    audioEn: '/media/audios/OnTheRoadAroundTheWorld.mp3',
  },
  {
    title: 'sound_world_record_7',
    image: 'soundWorld_recordCover_7',
    audioDe:
      '/media/audios/PauseAnDerPorscheChargingLounge_BreakAtThePorscheChargingLounge.mp3',
  },
  {
    title: 'sound_world_record_8',
    image: 'soundWorld_recordCover_8',
    audioDe: '/media/audios/UnterwegsImTaycan_OnTheRoadInTheTaycan.mp3',
  },
  {
    title: 'sound_world_record_9',
    image: 'soundWorld_recordCover_9',
    audioDe: '/media/audios/Urlaubserinnerungen_VacationMemories.mp3',
  },
  {
    title: 'sound_world_record_10',
    image: 'soundWorld_recordCover_10',
    audioDe: '/media/audios/EinTagimGruenen_ADayInTheCountryside.mp3',
  },
];

const SoundWorld = () => {
  const scope = 'SoundWorld';
  const systemDevice = useSystemDevice();
  const { t, i18n } = useTranslation();
  const imagesFolder = useGetImagesFolder();
  const audioPlayersRef = useRef<(H5AudioPlayer | null)[]>([]);

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={`${scope}-heading`}>{t('sound_world_heading')}</h1>
      <Paragraph
        className={`${scope}-description`}
        translateKey={'sound_world_description'}
      />
      <div className={`${scope}-records`}>
        {SOUND_RECORDS.map((record, index) => (
          <div className={`${scope}-record`} key={index}>
            <div className={`${scope}-recordCover`}>
              <img
                alt={t(record.title)}
                className={`${scope}-recordCoverImage`}
                src={`${imagesFolder}/${i18n.language}/${record.image}.jpg`}
              />
              <h2 className={`${scope}-recordTitle`}>{t(record.title)}</h2>
            </div>
            <AudioPlayer
              ref={(audioPlayer) =>
                (audioPlayersRef.current[index] = audioPlayer)
              }
              src={`${process.env.PUBLIC_URL}${i18n.language === LANGUAGES[0] ? record.audioDe : record.audioEn ?? record.audioDe}`}
              onPlay={() => {
                for (const audioPlayer of audioPlayersRef.current) {
                  if (
                    audioPlayer !== audioPlayersRef.current[index] &&
                    audioPlayer?.isPlaying()
                  ) {
                    audioPlayer.audio.current?.pause();
                  }
                }
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SoundWorld;
