import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import './ScentingStation.scss';
import ScentingStationData from '../../assets/json/scenting-station.json';
import Image from '../../components/Image/Image';
import { useCarouselOptions } from '../../hooks/useCarouselOptions';
import { useGetImagesFolder } from '../../hooks/useGetImagesFolder';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import ScentingStationSingleItem from './ScentingStationSingleItem/ScentingStationSingleItem';

const ScentingStation = () => {
  const scope = 'ScentingStation';

  const systemDevice = useSystemDevice();
  const { t } = useTranslation();
  const carouselOptions = useCarouselOptions();
  const imagesFolder = useGetImagesFolder();

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={`${scope}-heading`}>{t('scenting_station')}</h1>
      <div className={`${scope}-mainTextContainer`}>
        <Image
          name={`scenting-station.png`}
          imageCustomPath={`${process.env.PUBLIC_URL}/media/images`}
          className={`${scope}-mainImage`}
          alt={'Scenting Station'}
        />
        <div>{t('scenting_station_main_text')}</div>
      </div>
      <Slider
        className={`${scope}-carousel`}
        {...carouselOptions}
        slidesToShow={3}
      >
        {ScentingStationData.map((scentingItemData) => {
          return (
            <ScentingStationSingleItem
              className={`${scope}-singleItem`}
              key={scentingItemData.title}
              data={scentingItemData}
            />
          );
        })}
      </Slider>
      <img
        src={`${imagesFolder}/scentingStation_landscape1.jpg`}
        className={`${scope}-bottom-image`}
        alt={'Scenting Station'}
      />
    </div>
  );
};

export default ScentingStation;
