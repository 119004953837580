import { createContext } from 'react';

export const MINUTE = 1000 * 60;
export const IDLE_TIMEOUT = 3 * MINUTE;
export const PROMPT_BEFORE_IDLE = 1 * MINUTE;

export const IdleContext = createContext({
  idleTimeout: IDLE_TIMEOUT,
  setIdleTimeout: (_newValue: number) => {},
  promptBeforeIdle: PROMPT_BEFORE_IDLE,
  setPromptBeforeIdle: (_newValue: number) => {},
});
