import './Faq.scss';
import { useTranslation } from 'react-i18next';
import FaqData from '../../assets/json/faq.json';
import { useSerialNumber } from '../../hooks/useSerialNumber';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import FaqItem from './FaqItem/FaqItem';

const Faq = () => {
  const scope = 'Faq';

  const systemDevice = useSystemDevice();
  const { t } = useTranslation();
  const serialNumber = useSerialNumber();

  const filteredData = FaqData.filter((item) => {
    if (!item.displayFor) {
      return true;
    }
    const serials = Array.isArray(item.displayFor)
      ? item.displayFor
      : [item.displayFor];
    return serials.includes(serialNumber);
  });

  const itemsLength = filteredData.length;

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={`${scope}-heading`}>{t('faq')}</h1>
      <div className={`${scope}-faqItemsContainer`}>
        {filteredData.map((data, index) => {
          return (
            <div key={index}>
              <FaqItem {...data} />
              {index < itemsLength - 1 && (
                <div className={`${scope}-separator`} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
