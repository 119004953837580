import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './View.scss';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import WaterDropData from '../../../assets/json/waterdrop.json';
import HydrationStationCollage from '../../../components/HydrationStationCollage/HydrationStationCollage';
import WaterDropItem from '../../../components/WaterDropSection/WaterDropItem';
import { useCarouselOptions } from '../../../hooks/useCarouselOptions';
import { useSystemDevice } from '../../../hooks/useSystemDevice';

const HydrationStationView = () => {
  const scope = 'HydrationStationView';

  const systemDevice = useSystemDevice();
  const params = useParams();
  const { t } = useTranslation();
  const carouselOptions = useCarouselOptions();

  const getCarouselActiveItemIndex = useCallback(() => {
    const activeIndex = WaterDropData.findIndex(
      (data) => data.page === params.page,
    );

    return activeIndex ?? 0;
  }, [params.page]);

  const [carouselActiveItemIndex] = useState(() =>
    getCarouselActiveItemIndex(),
  );

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={`${scope}-heading`}>{t('hydration_station')}</h1>
      <Slider
        className={`${scope}-carousel`}
        {...carouselOptions}
        initialSlide={carouselActiveItemIndex}
      >
        {WaterDropData.map((columnItem) => {
          return (
            <WaterDropItem key={columnItem.title} data={columnItem.details} />
          );
        })}
      </Slider>
      <HydrationStationCollage />
      <p className={`${scope}-legal`}>{t('hydration_station_legal')}</p>
    </div>
  );
};

export default HydrationStationView;
