import { useGetImagesFolder } from '../../hooks/useGetImagesFolder';
import { useSystemDevice } from '../../hooks/useSystemDevice';
import './HydrationStationCollage.scss';

export default function HydrationStationCollage() {
  const scope = 'HydrationStationCollage';
  const systemDevice = useSystemDevice();
  const imagesFolder = useGetImagesFolder();

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      {Array.from({ length: 5 }, (_, index) => {
        const name = `collage${index + 1}`;
        const style = {
          backgroundImage: `url(${`${imagesFolder}/hydrationStation_${name}.jpg`})`,
        };
        return (
          <div
            key={name}
            className={`${scope}-image ${scope}-${name}`}
            style={style}
          ></div>
        );
      })}
    </div>
  );
}
