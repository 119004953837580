import './ImageTileWithText.scss';
import { useTranslation } from 'react-i18next';
import BrainTeaserData from '../../assets/json/brain-teaser.json';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { useGetCurrentPathWithoutLanguage } from '../../hooks/useGetCurrentPathWithoutLanguage';
import { useGetImagesFolder } from '../../hooks/useGetImagesFolder';
import { useSystemDevice } from '../../hooks/useSystemDevice';

interface Args {
  data: (typeof BrainTeaserData)[0];
  text?: string;
  className?: string;
}

const ImageTileWithText = ({ data, className = '' }: Args) => {
  const scope = 'ImageTileWithText';

  const systemDevice = useSystemDevice();
  const customNavigate = useCustomNavigate();
  const getCurrentPath = useGetCurrentPathWithoutLanguage();
  const { t } = useTranslation();
  const imagesFolder = useGetImagesFolder();

  const { page, title, image } = data;

  return (
    <div className={`${scope} ${scope}--${systemDevice} ${className}`}>
      <a
        className={`${scope}-link`}
        onClick={() => customNavigate({ page: `${getCurrentPath()}/${page}` })}
      >
        <img
          src={`${imagesFolder}/${image}`}
          alt={t(title)}
          className={`${scope}-image`}
        />
        <div className={`${scope}-text`}>{t(title)}</div>
      </a>
    </div>
  );
};

export default ImageTileWithText;
