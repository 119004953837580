import './Footer.scss';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ModalContext } from '../../context/ModalContext';
import { useCustomNavigate } from '../../hooks/useCustomNavigate';
import { SYSTEM_DEVICE, useSystemDevice } from '../../hooks/useSystemDevice';
import Modal from '../Modal/Modal';
import QRContainer from '../QRContainer/QRContainer';
import SvgIcon from '../SvgIcon/SvgIcon';

export default function Footer() {
  const scope = 'Footer';
  const systemDevice = useSystemDevice();
  const { t } = useTranslation();
  const customNavigate = useCustomNavigate();
  const { setVisibleModal } = useContext(ModalContext);
  const modalName = 'vahaModal';

  const isVaha = systemDevice === SYSTEM_DEVICE.VAHA;

  return (
    <footer className={`${scope} ${scope}--${systemDevice}`}>
      {isVaha && (
        <button
          type="button"
          className={scope + '-logoButton'}
          onClick={() => setVisibleModal(modalName)}
        >
          <SvgIcon className={scope + '-logo'} name={`vaha`} />
        </button>
      )}
      <div className={`${scope}-footerLinks`}>
        <a
          className={scope + '-imprint'}
          onClick={() => customNavigate({ page: 'imprint' })}
        >
          {t('imprint')}
        </a>
        {!isVaha && (
          <a
            className={scope + '-privacyPolicy'}
            onClick={() => customNavigate({ page: 'privacy-policy' })}
          >
            {t('privacy_policy')}
          </a>
        )}
      </div>

      <Modal
        name={modalName}
        onClose={() => setVisibleModal('')}
        className={`${scope}-modal ${scope}-modal--${systemDevice}`}
      >
        <div className={`${scope}-modalText`}>
          {t('vaha_mirror_description')}
        </div>
        <QRContainer
          value={'https://de.vaha.com/'}
          className={`${scope}-qrCode`}
          text={'get_yours_here'}
        />
      </Modal>
    </footer>
  );
}
