import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './View.scss';
import { useParams } from 'react-router-dom';
import stretchFlowData from '../../../assets/json/stretch-flow.json';
import ExerciseVideo from '../../../components/ExerciseVideo/ExerciseVideo';
import QRContainer from '../../../components/QRContainer/QRContainer';
import { toKebabCase } from '../../../helpers/toKebabCase';
import { useGetCurrentUrlForQrCode } from '../../../hooks/useGetCurrentUrlForQrCode';
import { useGetImagesFolder } from '../../../hooks/useGetImagesFolder';
import { SYSTEM_DEVICE, useSystemDevice } from '../../../hooks/useSystemDevice';
import { GalleryMedia } from '../../../types/GalleryMedia';

function getItem(name: string, stretchFlowData: GalleryMedia[]) {
  return stretchFlowData.find((item) => toKebabCase(item.name) === name);
}

export default function StretchFlowView() {
  const scope = 'StretchFlowView';
  const systemDevice = useSystemDevice();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [item] = useState(
    getItem(params.view as string, stretchFlowData) as GalleryMedia,
  );
  const imagesFolder = useGetImagesFolder();
  const qrUrl = useGetCurrentUrlForQrCode();

  const description = t(
    i18n.exists(`${item.name}_description`) ? `${item.name}_description` : '',
  );
  const languageSpecificFolder = i18n.language;
  const introPath = `/media/videos/stretch-flow/${languageSpecificFolder}/Porsche_00_Intro.mp4`;

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      {item && (
        <>
          <h1 className={scope + '-heading'}>{t(item.name)}</h1>
          {description && (
            <p className={scope + '-description'}>{description}</p>
          )}
          <ExerciseVideo
            introSource={`${process.env.PUBLIC_URL}${introPath}`}
            source={`${process.env.PUBLIC_URL}/media/videos/stretch-flow/${languageSpecificFolder}/${item.video}`}
            previewSource={`${imagesFolder}/preview/${item.image}`}
          />
          {systemDevice === SYSTEM_DEVICE.VAHA && (
            <QRContainer value={qrUrl} className={`${scope}-qrCode`} />
          )}
        </>
      )}
    </div>
  );
}
