import './BrainTeaser.scss';
import { useTranslation } from 'react-i18next';
import BrainTeaserData from '../../assets/json/brain-teaser.json';
import ImageTileWithText from '../../components/ImageTileWithText/ImageTileWithText';
import Paragraph from '../../components/Paragraph/Paragraph';
import { useSystemDevice } from '../../hooks/useSystemDevice';

const BrainTeaser = () => {
  const scope = 'BrainTeaser';

  const systemDevice = useSystemDevice();
  const { t } = useTranslation();

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <h1 className={`${scope}-heading`}>{t('brain_teaser')}</h1>
      <Paragraph
        className={`${scope}-description`}
        translateKey={'brain_teaser_description'}
      />
      {BrainTeaserData.map((teaserItem) => {
        return (
          <ImageTileWithText
            key={teaserItem.title}
            data={teaserItem}
            className={`${scope}-tileImage`}
          />
        );
      })}
    </div>
  );
};

export default BrainTeaser;
