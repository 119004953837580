import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function useCustomNavigate() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  return ({
    page = '',
    language = i18n.language,
  }: {
    page?: string;
    language?: string;
  }) => {
    const to = `/${language}${
      page ? (page.startsWith('/') ? page : `/${page}`) : ''
    }${window.location.search ?? ''}`;

    navigate(to);
  };
}
