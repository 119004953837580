import './MirrorMode.scss';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useIdleTimerContext } from 'react-idle-timer';
import Modal from '../../components/Modal/Modal';
import {
  IDLE_TIMEOUT,
  IdleContext,
  MINUTE,
  PROMPT_BEFORE_IDLE,
} from '../../context/IdleContext';
import { ModalContext } from '../../context/ModalContext';
import { useSystemDevice } from '../../hooks/useSystemDevice';

const MODAL_VIEW_TIMEOUT = 15000;
const MIRROR_MODE_IDLE_TIMEOUT = 1 * MINUTE;
const MIRROR_MODE_PROMPT_BEFORE_IDLE = 0.5 * MINUTE;

function MirrorMode() {
  const scope = 'MirrorMode';
  const systemDevice = useSystemDevice();
  const { visibleModal, setVisibleModal } = useContext(ModalContext);
  const { setIdleTimeout, setPromptBeforeIdle } = useContext(IdleContext);
  const idleTimer = useIdleTimerContext();
  const { t } = useTranslation();
  const modalName = 'selfieModal';
  const [modalViewed, setModalViewed] = useState(false);

  useEffect(() => {
    if (!visibleModal && !modalViewed) {
      setVisibleModal(modalName);
    }
  }, [setVisibleModal, modalName, visibleModal, modalViewed]);

  const hideModal = useCallback(() => {
    setModalViewed(true);
    setVisibleModal('');
    setIdleTimeout(MIRROR_MODE_IDLE_TIMEOUT);
    setPromptBeforeIdle(MIRROR_MODE_PROMPT_BEFORE_IDLE);
    idleTimer.start();
    return () => {
      setIdleTimeout(IDLE_TIMEOUT);
      setPromptBeforeIdle(PROMPT_BEFORE_IDLE);
    };
  }, [
    setModalViewed,
    setVisibleModal,
    setIdleTimeout,
    setPromptBeforeIdle,
    idleTimer,
  ]);

  useEffect(() => {
    if (!modalViewed) {
      const timer = setTimeout(() => hideModal(), MODAL_VIEW_TIMEOUT);
      return () => clearTimeout(timer);
    }
  }, [modalViewed, hideModal]);

  return (
    <div className={`${scope} ${scope}--${systemDevice}`}>
      <div className={`${scope}-hashtag`}>
        <h4>#rechargeyourself</h4>
      </div>
      <Modal name={modalName} className={`${scope}-modal`}>
        <h4 className={`${scope}-modalText`}>{t('grab_phone_snap_selfie')}</h4>
        <button className={`${scope}-go`} onClick={() => hideModal()}>
          {t('go')}
        </button>
      </Modal>
    </div>
  );
}

export default MirrorMode;
