import './Image.scss';
import { useTranslation } from 'react-i18next';
import { useGetImagesFolder } from '../../hooks/useGetImagesFolder';
import { useSystemDevice } from '../../hooks/useSystemDevice';

interface Args {
  name: string;
  className?: string;
  alt?: string;
  imageCustomPath?: string;
}

export default function Image({
  name,
  className: classes,
  alt,
  imageCustomPath,
}: Args) {
  const scope = 'Image';

  const systemDevice = useSystemDevice();
  const defaultImagesFolder = useGetImagesFolder();
  const { t } = useTranslation();

  const imagesFolder = imageCustomPath ?? defaultImagesFolder;

  const className = [scope, `${scope}--${systemDevice}`, classes]
    .filter((className) => className)
    .join(' ');

  return (
    <div className={className}>
      <img
        className={`${scope}-source`}
        src={`${imagesFolder}/${name}`}
        alt={alt ? `${t(alt)}` : ''}
      />
    </div>
  );
}
